import { Typography } from "@mui/material";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";
import "react18-json-view/src/style.css";

interface EmailBodyProps {
  email: any;
}

function EmailBody({ email }: EmailBodyProps) {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="body2" component="p" sx={{ mb: 1, fontWeight: "bold" }}>
        {t("common.sender")}:{" "}
        <Typography variant="body2" sx={{ display: "inline" }}>
          {email.sender}
        </Typography>
      </Typography>
      {email.original_sender && (
        <Typography variant="body2" component="p" sx={{ mb: 1, fontWeight: "bold" }}>
          {t("common.originalSender")}:{" "}
          <Typography variant="body2" sx={{ display: "inline" }}>
            {email.original_sender}
          </Typography>
        </Typography>
      )}
      <Typography variant="body2" component="p" sx={{ mb: 1, fontWeight: "bold", lineHeight: 1.5 }}>
        {t("common.recipient")}:{" "}
        <Typography variant="body2" sx={{ display: "inline" }}>
          {email.recipient}
        </Typography>
      </Typography>
      <Typography variant="body2" component="p" sx={{ mb: 1, fontWeight: "bold", lineHeight: 1.5 }}>
        {t("common.subject")}:{" "}
        <Typography
          variant="body2"
          sx={{ display: "inline" }}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(email.subject) }}
        />
      </Typography>
      <Typography variant="body2" component="p" sx={{ mb: 1, fontWeight: "bold", lineHeight: 2 }}>
        {t("common.body")}:{" "}
        <Typography
          variant="body2"
          sx={{ display: "inline", lineHeight: 1 }}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(email.body_html) }}
        />
      </Typography>
    </>
  );
}

export default EmailBody;
