import { Article, GridOn, Image, InsertDriveFile, PictureAsPdf } from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import FolderOffIcon from "@mui/icons-material/FolderOff";
import { IconButton, List, ListItem, ListItemText, Stack, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IArtifactRead } from "../../../../auth/types";

interface IEmailArtifactsTabProps {
  artifacts: IArtifactRead[];
}

export default function EmailArtifactsTab(props: IEmailArtifactsTabProps) {
  const { t } = useTranslation();

  const getFileIcon = (filename: string) => {
    const extension = filename.split(".").pop()?.toLowerCase();
    if (extension === "pdf") {
      return <PictureAsPdf />;
    } else if (extension === "txt") {
      return <Article />;
    } else if (extension === "jpg") {
      return <Image />;
    } else if (extension === "csv" || extension === "xlsx" || extension === "xls") {
      return <GridOn />;
    } else {
      return <InsertDriveFile />;
    }
  };

  return (
    <>
      {props.artifacts.length > 0 ? (
        <List>
          {props.artifacts.map((artifact) => (
            <ListItem
              key={artifact.id}
              divider
              secondaryAction={
                <Tooltip title={t("common.download")}>
                  <IconButton
                    edge="end"
                    color="primary"
                    component="a"
                    href={artifact.presigned_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <DownloadIcon />
                  </IconButton>
                </Tooltip>
              }
            >
              <ListItemText primary={artifact.filename} />
            </ListItem>
          ))}
        </List>
      ) : (
        <Stack alignItems="center" justifyContent="center" sx={{ py: 6 }}>
          <FolderOffIcon sx={{ fontSize: 60, color: "text.secondary", mb: 2 }} />
          <Typography variant="h6" align="center" color="textSecondary" sx={{ mb: 2 }}>
            {t("artifacts.noArtifactsAvailable")}
          </Typography>
          <Typography variant="body2" align="center" color="textSecondary">
            {t("artifacts.noArtifactsAvailableDescription")}
          </Typography>
        </Stack>
      )}
    </>
  );
}
