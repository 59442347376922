import RuleIcon from "@mui/icons-material/Rule";
import { Box, Card, CardContent, Checkbox, CircularProgress, Link, Stack, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useGetNteCode88ItemsQuery, useUpdateNteCode88ItemsMutation } from "../../../../../app/services/appApi";
import { IEmailRecord } from "../../../emailTypes";
import { TabPlugin } from "./TabPluginRegistry";

interface NteItem {
  descr_forn: string;
  email: string;
  evaso: boolean;
  id: number;
  note: string;
  qta: number;
}

const NteCode88Tab: React.FC<{ form: UseFormReturn<any>; email: IEmailRecord }> = ({ form }) => {
  const workOrder = form.watch("order_number.value");

  // Fetch items only when a work order is provided.
  const { data, isLoading } = useGetNteCode88ItemsQuery(workOrder, {
    skip: !workOrder,
  });
  const [updateItems, { isLoading: isSaving }] = useUpdateNteCode88ItemsMutation();

  const [expandedItems, setExpandedItems] = React.useState<number[]>([]);

  const handleToggle = async (id: number) => {
    if (!data) return;
    // update evaso
    const updatedItems = data.items.map((item) => (item.id === id ? { ...item, evaso: !item.evaso } : item));
    try {
      await updateItems({ items: updatedItems }).unwrap();
      enqueueSnackbar("Valore aggiornato con successo", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Impossibile salvare. Riprova più tardi.", { variant: "error" });
    }
  };

  const handleShowMore = (id: number) => {
    setExpandedItems((prev) => (prev.includes(id) ? prev.filter((itemId) => itemId !== id) : [...prev, id]));
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight={200}>
        <CircularProgress />
      </Box>
    );
  }

  if (!data?.items.length) {
    return (
      <Stack alignItems="center" justifyContent="center" sx={{ py: 6 }}>
        <RuleIcon sx={{ fontSize: 60, color: "text.secondary", mb: 2 }} />
        <Typography variant="h6" align="center" color="textSecondary" sx={{ mb: 2 }}>
          Nessun codice 00088 trovato
        </Typography>
        <Typography variant="body2" align="center" color="textSecondary">
          Sembra che l'ordine "{workOrder}" non abbia alcun codice associato.
        </Typography>
      </Stack>
    );
  }

  return (
    <Box p={2}>
      <Stack spacing={2}>
        {data.items.map((item: NteItem) => {
          const bulletPoints = item.note ? item.note.split("\n").filter((line) => line.trim().startsWith("-")) : [];

          const isExpanded = expandedItems.includes(item.id);

          return (
            <Card key={item.id}>
              <CardContent>
                <Box display="flex" alignItems="center" width="100%">
                  <Checkbox
                    edge="start"
                    checked={item.evaso}
                    onChange={() => handleToggle(item.id)}
                    disabled={isSaving}
                  />
                  <Box ml={2} flexGrow={1}>
                    <Typography variant="body1">
                      {item.descr_forn} ({item.qta} q.tà)
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {item.email}
                    </Typography>
                  </Box>
                </Box>
                <Box mt={2}>
                  {bulletPoints.length > 0 ? (
                    <>
                      {bulletPoints.map((line, index) => (
                        <Typography variant="body2" key={index} sx={{ mb: 0.5 }}>
                          {line.trim()}
                        </Typography>
                      ))}
                      {item.note && (
                        <Link component="button" variant="body2" onClick={() => handleShowMore(item.id)} sx={{ mt: 1 }}>
                          {isExpanded ? "Mostra meno" : "Mostra tutto il testo"}
                        </Link>
                      )}
                      {isExpanded && (
                        <Typography variant="body2" sx={{ mt: 1, whiteSpace: "pre-wrap" }}>
                          {item.note}
                        </Typography>
                      )}
                    </>
                  ) : (
                    <Typography variant="body2" color="text.secondary">
                      Nessuna nota disponibile.
                    </Typography>
                  )}
                </Box>
              </CardContent>
            </Card>
          );
        })}
      </Stack>
    </Box>
  );
};

const nteCode88Plugin: TabPlugin = {
  id: "nteCode88",
  label: "Codici 00088",
  priority: -10,
  render: (form, email) => <NteCode88Tab form={form} email={email} />,
  shouldLoad: (workflowName) => workflowName === "nte_doc_cls",
};

export default nteCode88Plugin;
