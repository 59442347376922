import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import React from "react";
import JsonView from "react18-json-view";

const Section = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  "& > h2": {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
}));

const SubSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  "& > h3": {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1),
  },
}));

const ExampleBox = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.grey[50],
  "& .json-view": {
    backgroundColor: "transparent !important",
  },
}));

const FieldType = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontFamily: "monospace",
  backgroundColor: theme.palette.grey[100],
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.spacing(0.5),
  display: "inline-block",
}));

const SchemaDocsDialog = () => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const fieldExamples = {
    string: {
      field_name: {
        priority: 0,
        type: "string",
        label: "String Field",
        validation: {
          required: true,
          maxLength: 40,
        },
      },
    },
    float: {
      quantity: {
        priority: 0,
        type: "float",
        label: "Quantity",
        validation: {
          required: true,
          min: 0,
          max: 1000,
        },
      },
    },
    date: {
      delivery_date: {
        priority: 0,
        type: "date",
        label: "Delivery Date",
        validation: {
          required: true,
        },
      },
    },
    enum: {
      status: {
        priority: 0,
        type: "enum",
        label: "Status",
        options: [
          { value: "", label: "Select status" },
          { value: "NEW", label: "New Order" },
          { value: "PROCESSING", label: "Processing" },
          { value: "COMPLETED", label: "Completed" },
        ],
        validation: {
          required: true,
        },
      },
    },
    search: {
      customer: {
        priority: 0,
        type: "search",
        label: "Customer",
        searchModel: "customer",
        validation: {
          required: true,
        },
      },
    },
    multiline: {
      notes: {
        priority: 0,
        type: "multiline",
        label: "Notes",
        validation: {
          maxLength: 500,
        },
      },
    },
  };

  const keyExample = {
    orderInfo: {
      priority: "...",
      title: "...",
      fields: {
        order_number: {
          priority: "...",
          type: "...",
          label: "...",
        },
      },
    },
  };

  const completeExample = {
    orderInfo: {
      priority: 0,
      title: "Order Details",
      fields: {
        order_number: {
          priority: 0,
          type: "string",
          label: "Order Number",
          validation: {
            required: true,
            maxLength: 10,
          },
        },
        order_date: {
          priority: -1,
          type: "date",
          label: "Order Date",
          validation: {
            required: true,
          },
        },
        customer_id: {
          priority: -2,
          type: "search",
          label: "Customer",
          searchModel: "customer",
          validation: {
            required: true,
          },
        },
        order_type: {
          priority: -3,
          type: "enum",
          options: [
            { value: "", label: "Select type" },
            { value: "STANDARD", label: "Standard Order" },
            { value: "RUSH", label: "Rush Order" },
            { value: "SPECIAL", label: "Special Order" },
          ],
          label: "Order Type",
          validation: {
            required: true,
          },
        },
      },
    },
    items: {
      priority: -1,
      title: "Order Items",
      fields: {
        product_id: {
          priority: 0,
          type: "search",
          label: "Product",
          searchModel: "product",
          validation: {
            required: true,
          },
        },
        quantity: {
          priority: -1,
          type: "float",
          label: "Quantity",
          validation: {
            required: true,
            min: 1,
          },
        },
        notes: {
          priority: -2,
          type: "multiline",
          label: "Item Notes",
          validation: {
            maxLength: 200,
          },
        },
      },
    },
  };

  return (
    <>
      <InputAdornment position="end">
        <IconButton onClick={handleOpen} size="small" edge="end" aria-label="schema documentation">
          <HelpOutlineIcon />
        </IconButton>
      </InputAdornment>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle>
          <Typography variant="h4" component="h1">
            UI Schema Documentation
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Section>
            <Typography variant="h5" component="h2">
              Sections
            </Typography>
            <Typography variant="body1" gutterBottom>
              The UI schema is organized into sections (which will render as collapsible), each with its own title and
              collection of fields. Each section can have multiple fields, and fields are rendered in order of their
              priority (higher numbers first). All fields in the section MUST be flat (no nested objects). Also sections
              can have a priority to determine their order in the form.
            </Typography>
            <Typography variant="body1" gutterBottom>
              The special <FieldType>items</FieldType> section is treated as an array, allowing users to add multiple
              entries.
            </Typography>
          </Section>

          <Section>
            <Typography variant="h5" component="h2">
              Property key
            </Typography>
            <Typography variant="body1" component="div">
              The property key is used to identify the JSON path where to load/store the data. So the format is{" "}
              <FieldType>sectionName.fieldName</FieldType>. All fields in the section MUST be flat (no nested objects).
              If the key is <FieldType>orderInfo.order_number</FieldType>, the data will be stored/loaded from the
              extracted json at <FieldType>order_number.value</FieldType> object. The section name is ignored. Example
              JSON UI:
              <ExampleBox>
                <JsonView src={keyExample} theme="atom" />
              </ExampleBox>
              Example JSON data:
              <ExampleBox>
                <JsonView src={{ order_number: { value: "123" } }} theme="atom" />
              </ExampleBox>
            </Typography>
          </Section>

          <Section>
            <Typography variant="h5" component="h2">
              Common Properties
            </Typography>
            <Typography variant="body1" component="div">
              All fields support these common properties:
              <ul>
                <li>
                  <FieldType>priority</FieldType>: Number determining field order (higher numbers first)
                </li>
                <li>
                  <FieldType>type</FieldType>: The field type (string, float, date, etc.)
                </li>
                <li>
                  <FieldType>label</FieldType>: Display label for the field
                </li>
                <li>
                  <FieldType>validation</FieldType>: Object containing validation rules
                </li>
              </ul>
            </Typography>
          </Section>

          <Section>
            <Typography variant="h5" component="h2">
              Field Types
            </Typography>

            <SubSection>
              <Typography variant="h6" component="h3">
                String Field
              </Typography>
              <Typography variant="body1">
                Basic single-line text input. Supports validation for required status and maximum length.
              </Typography>
              <ExampleBox>
                <JsonView src={fieldExamples.string} theme="atom" />
              </ExampleBox>
            </SubSection>

            <SubSection>
              <Typography variant="h6" component="h3">
                Float Field
              </Typography>
              <Typography variant="body1">
                Numeric input for decimal values. Supports validation for required status, minimum, and maximum values.
              </Typography>
              <ExampleBox>
                <JsonView src={fieldExamples.float} theme="atom" />
              </ExampleBox>
            </SubSection>

            <SubSection>
              <Typography variant="h6" component="h3">
                Date Field
              </Typography>
              <Typography variant="body1">Date picker input. Supports required validation.</Typography>
              <ExampleBox>
                <JsonView src={fieldExamples.date} theme="atom" />
              </ExampleBox>
            </SubSection>

            <SubSection>
              <Typography variant="h6" component="h3">
                Enum Field
              </Typography>
              <Typography variant="body1">
                Dropdown selection with predefined options. Each option must have a value and label. The empty value
                option is typically used as a placeholder.
              </Typography>
              <ExampleBox>
                <JsonView src={fieldExamples.enum} theme="atom" />
              </ExampleBox>
            </SubSection>

            <SubSection>
              <Typography variant="h6" component="h3">
                Search Field
              </Typography>
              <Typography variant="body1">
                Searchable dropdown connected to a backend model. The searchModel property defines which entity type to
                search.
              </Typography>
              <ExampleBox>
                <JsonView src={fieldExamples.search} theme="atom" />
              </ExampleBox>
            </SubSection>

            <SubSection>
              <Typography variant="h6" component="h3">
                Multiline Field
              </Typography>
              <Typography variant="body1">
                Multi-line text input for longer text content. Supports validation for required status and maximum
                length.
              </Typography>
              <ExampleBox>
                <JsonView src={fieldExamples.multiline} theme="atom" />
              </ExampleBox>
            </SubSection>
          </Section>

          <Section>
            <Typography variant="h5" component="h2">
              Complete Example
            </Typography>
            <Typography variant="body1" gutterBottom>
              Below is a comprehensive example showing a typical order form schema with multiple sections and field
              types:
            </Typography>
            <ExampleBox>
              <JsonView src={completeExample} theme="atom" />
            </ExampleBox>
          </Section>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SchemaDocsDialog;
