import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IAddressRecord, IAddressRequest } from "../../features/address/addressTypes";

import { SyncStatus } from "@features/Sync/syncTypes";
import { ILoginRequest, ILoginResponse } from "@features/auth/authTypes";
import {
  IOrganizationCreate,
  IOrganizationRead,
  IOrganizationUpdate,
  IUserCreate,
  IUserRead,
  IUserUpdate,
  IWorkflowCreate,
  IWorkflowRead,
  IWorkflowUpdate,
} from "../../features/auth/types";
import {
  IBenchmarkReportListItem,
  IBenchmarkReportRecord,
  IBenchmarkReportRequest,
} from "../../features/benchmark/benchmarkTypes";
import { IContactRecord, IContactRequest } from "../../features/contact/contactTypes";
import { IDebugResponse } from "../../features/debug/debugTypes";
import { IEmailNavRequest, IEmailRecord } from "../../features/email/emailTypes";
import { IGatewayEventRecord } from "../../features/gateway/gatewayTypes";
import { IAutocompleteRequest, IAutocompleteResponse } from "../../features/input/select/ServerSideSearchSelect2";
import { IProductRecord, IProductRequest } from "../../features/product/productTypes";
import { SheetImportStatus } from "../../features/sheet_import/sheetImportTypes";
import { IListResponse, ISearchRequest } from "./apiTypes";

export const API_PATH = "/api/v1/"; // Only url part, without localhost or IP. Must always end with a slash

const baseQuery = fetchBaseQuery({
  baseUrl: API_PATH,
  prepareHeaders: (headers, { getState }) => {
    // Add default Content-Type header unless they are already set. case-insensitive
    // if (!headers.has("Content-Type") && !headers.has("content-type")) {
    //   headers.set("Content-Type", "application/json");
    // }
    headers.set("Accept", "application/json; charset=utf-8");
    // Append token to every request
    const token = localStorage.getItem("token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const baseQueryWithLogout = async (args: any, api: any, extraOptions: any) => {
  let result = await baseQuery(args, api, extraOptions);

  // Handle authentication errors
  if (result.error && result.error.status === 401) {
    // const isMissingToken = result.error.data.code === "MISSING_TOKEN";
    const isInvalidToken = (result.error.data as { code?: string })?.code === "INVALID_TOKEN";
    const isExpiredToken = (result.error.data as { code?: string })?.code === "EXPIRED_TOKEN";

    if (isInvalidToken || isExpiredToken) {
      localStorage.removeItem("token");
      // Invalidate Auth cache
      api.invalidateTags(["USER"]);
    }
  }
  return result;
};

export const appApi = createApi({
  reducerPath: "appApi",
  baseQuery: baseQueryWithLogout,
  tagTypes: ["USER", "EMAIL", "PRODUCT", "CONTACT", "WORKFLOW", "BENCHMARK", "ORGANIZATION", "ADDRESS", "NTE_CODE88"],

  endpoints: (builder) => ({
    getPolicy: builder.query<{ model: string; policy: Array<[string, string, string, string]> }, void>({
      query: () => "/authz",
      providesTags: ["USER"],
    }),

    login: builder.mutation<ILoginResponse, ILoginRequest>({
      query: ({ username, password }) => ({
        url: "/user/login",
        method: "POST",
        body: { username, password },
      }),
      // Update auth only on successful attempt to refetch user data
      invalidatesTags: (result) => (result ? ["USER", "EMAIL", "PRODUCT", "WORKFLOW", "ADDRESS"] : []),
      // Keep token in localStorage
      transformResponse: (response: ILoginResponse) => {
        if (response.token) {
          localStorage.setItem("token", response.token);
        }
        return response;
      },
    }),

    logout: builder.mutation<void, void>({
      query: () => ({
        url: "/user/logout",
        method: "POST",
      }),
      invalidatesTags: ["USER", "EMAIL", "PRODUCT", "WORKFLOW", "ADDRESS"],
      transformResponse: (response: void) => {
        // Remove token from localStorage
        localStorage.removeItem("token");
        return response;
      },
    }),

    currentUser: builder.query<IUserRead, void>({
      query: () => "/user/current",
      providesTags: ["USER"],
    }),

    getUser: builder.query<IUserRead, IUserRead["id"]>({
      query: (userId) => `/user/${userId}`,
      providesTags: ["USER"],
    }),

    getUsers: builder.query<IListResponse<IUserRead>, ISearchRequest>({
      query: ({ page, pageSize, search }) => {
        const queryParams = new URLSearchParams();
        if (page !== undefined) {
          queryParams.append("page", page.toString());
        }
        if (pageSize !== undefined) {
          queryParams.append("page_size", pageSize.toString());
        }
        if (search !== undefined && search !== "") {
          queryParams.append("search", search);
        }
        queryParams.append("show_all", "true");
        return `/user?${queryParams.toString()}`;
      },
      providesTags: ["USER"],
    }),

    createUser: builder.mutation<IUserRead, IUserCreate>({
      query: (user) => ({
        url: "/user",
        method: "POST",
        body: user,
      }),
      invalidatesTags: ["USER"],
    }),

    updateUser: builder.mutation<IUserRead, IUserUpdate & { id: IUserRead["id"] }>({
      query: ({ id, ...user }) => ({
        url: `/user/${id}`,
        method: "PUT",
        body: user,
      }),
      invalidatesTags: ["USER"],
    }),

    deleteUser: builder.mutation<void, IUserRead["id"]>({
      query: (userId) => ({
        url: `/user/${userId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["USER"],
    }),

    // Email

    getEmail: builder.query<IEmailRecord, number>({
      query: (id) => `/email/${id}`,
      providesTags: ["EMAIL"],
    }),

    generateCsv: builder.mutation<{ content: string }, number[]>({
      query: (emailIds) => ({
        url: `/email/csv?ids=${emailIds.join(",")}`,
        method: "POST",
      }),
    }),

    getEmails: builder.query<IListResponse<IEmailRecord>, ISearchRequest>({
      query: ({ page, pageSize }) => `/email?page=${page}&page_size=${pageSize}`,
      providesTags: ["EMAIL"],
    }),

    getWorkflowEmails: builder.query<IListResponse<IEmailRecord>, { workflowId: number } & ISearchRequest>({
      query: ({ workflowId, page, pageSize, search }) => {
        const queryParams = new URLSearchParams();
        if (page !== undefined) {
          queryParams.append("page", page.toString());
        }
        if (pageSize !== undefined) {
          queryParams.append("page_size", pageSize.toString());
        }
        if (search !== undefined && search !== "") {
          queryParams.append("search", search);
        }
        return `/workflow/${workflowId}/email?${queryParams.toString()}`;
      },
      providesTags: ["EMAIL", "WORKFLOW"],
    }),

    patchEmail: builder.mutation<IEmailRecord, { id: number; body: Pick<IEmailRecord, "parsed_info"> }>({
      query: ({ id, body }) => ({
        url: `/email/${id}`,
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: ["EMAIL"],
    }),

    certifyEmail: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/email/${id}/certify`,
        method: "POST",
      }),
      invalidatesTags: ["EMAIL"],
    }),

    registerEmail: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/email/${id}/register`,
        method: "POST",
      }),
      invalidatesTags: ["EMAIL"],
    }),

    reprocessEmail: builder.mutation<void, number>({
      query: (emailId) => ({
        url: `/email/${emailId}/reprocess`,
        method: "POST",
      }),
      invalidatesTags: ["EMAIL"],
    }),

    getEmailNavigation: builder.query<IEmailNavRequest, number>({
      query: (emailId) => `/email/navigation?email_id=${emailId}`,
      providesTags: ["EMAIL"],
    }),

    deleteEmail: builder.mutation<void, number>({
      query: (emailId) => ({
        url: `/email/${emailId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["EMAIL"],
    }),

    uploadEmail: builder.mutation<
      { message: string; email_id?: string; task_id?: string; results?: Array<{ email_id: string; task_id: string }> },
      { workflowId: number; files: FormData; group: boolean }
    >({
      query: ({ workflowId, files, group }) => {
        return {
          url: `/email/upload?id=${workflowId}&group=${group}`,
          method: "POST",
          body: files,
          formData: true,
        };
      },
      invalidatesTags: ["EMAIL"],
    }),

    debugInfo: builder.query<IDebugResponse, void>({
      query: () => `/debug`,
    }),

    uploadFile: builder.mutation<
      { status: string; message: string },
      { recordType: "products" | "contacts"; file: File }
    >({
      query: ({ recordType, file }) => {
        let formData = new FormData();
        formData.append("file", file);
        return {
          url: `/${recordType === "contacts" ? "contact" : "product"}/batch`,
          method: "POST",
          body: formData,
          formData: true,
        };
      },
      invalidatesTags: ["PRODUCT", "CONTACT"],
    }),

    getProducts: builder.query<IListResponse<IProductRecord>, ISearchRequest>({
      query: ({ page, pageSize, search }) => {
        const queryParams = new URLSearchParams();
        if (page !== undefined) {
          queryParams.append("page", page.toString());
        }
        if (pageSize !== undefined) {
          queryParams.append("page_size", pageSize.toString());
        }
        if (search !== undefined && search !== "") {
          queryParams.append("search", search);
        }
        return `/product?${queryParams.toString()}`;
      },
      providesTags: ["PRODUCT"],
    }),

    getProduct: builder.query<IProductRecord, IProductRecord["id"]>({
      query: (productId) => `/product/${productId}`,
      providesTags: ["PRODUCT"],
    }),

    createProduct: builder.mutation<IProductRecord, IProductRequest>({
      query: (product) => ({
        url: `/product`,
        method: "POST",
        body: product,
      }),
      invalidatesTags: ["PRODUCT"],
    }),

    updateProduct: builder.mutation<IProductRecord, IProductRequest & { id: IProductRecord["id"] }>({
      query: ({ id, ...product }) => ({
        url: `/product/${id}`,
        method: "PUT",
        body: product,
      }),
      invalidatesTags: ["PRODUCT"],
    }),

    deleteProduct: builder.mutation<void, Pick<IProductRecord, "id">>({
      query: (productId) => ({
        url: `/product/${productId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PRODUCT"],
    }),

    //
    // Addresses
    //

    getAddresses: builder.query<IListResponse<IAddressRecord>, ISearchRequest & IAddressRequest>({
      query: ({ page, pageSize, search }) => {
        const queryParams = new URLSearchParams();
        if (page !== undefined) {
          queryParams.append("page", page.toString());
        }
        if (pageSize !== undefined) {
          queryParams.append("page_size", pageSize.toString());
        }
        if (search !== undefined && search !== "") {
          queryParams.append("search", search);
        }
        return `/address?${queryParams.toString()}`;
      },
      providesTags: ["ADDRESS"],
    }),

    getAddress: builder.query<IAddressRecord, IAddressRecord["id"]>({
      query: (addressId) => `/address/${addressId}`,
      providesTags: ["ADDRESS"],
    }),

    createAddress: builder.mutation<IAddressRecord, IAddressRequest>({
      query: (address) => ({
        url: "/address",
        method: "POST",
        body: address,
      }),
      invalidatesTags: ["ADDRESS"],
    }),

    updateAddress: builder.mutation<IAddressRecord, IAddressRequest & { id: IAddressRecord["id"] }>({
      query: ({ id, ...address }) => ({
        url: `/address/${id}`,
        method: "PUT",
        body: address,
      }),
      invalidatesTags: ["ADDRESS"],
    }),

    deleteAddress: builder.mutation<void, Pick<IAddressRecord, "id">>({
      query: (addressId) => ({
        url: `/address/${addressId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ADDRESS"],
    }),

    //
    // Workflows
    //
    getWorkflows: builder.query<IListResponse<IWorkflowRead>, { show_all?: boolean } & ISearchRequest>({
      query: (props) => {
        return `/workflow?show_all=${props.show_all ? "true" : "false"}`;
      },
      providesTags: ["WORKFLOW"],
    }),

    getWorkflow: builder.query<IWorkflowRead, IWorkflowRead["id"]>({
      query: (workflowId) => `/workflow/${workflowId}`,
      providesTags: ["WORKFLOW"],
    }),

    createWorkflow: builder.mutation<IWorkflowRead, IWorkflowCreate>({
      query: (workflow) => ({
        url: `/workflow`,
        method: "POST",
        body: workflow,
      }),
      invalidatesTags: ["WORKFLOW"],
    }),

    updateWorkflow: builder.mutation<IWorkflowRead, IWorkflowUpdate & { id: IWorkflowRead["id"] }>({
      query: ({ id, ...workflow }) => ({
        url: `/workflow/${id}`,
        method: "PUT",
        body: workflow,
      }),
      invalidatesTags: ["WORKFLOW"],
    }),

    deleteWorkflow: builder.mutation<void, IWorkflowRead["id"]>({
      query: (workflowId) => ({
        url: `/workflow/${workflowId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["WORKFLOW"],
    }),

    //
    // Contacts
    //

    getContacts: builder.query<IListResponse<IContactRecord>, ISearchRequest>({
      query: ({ page, pageSize, search }) => {
        const queryParams = new URLSearchParams();
        if (page !== undefined) {
          queryParams.append("page", page.toString());
        }
        if (pageSize !== undefined) {
          queryParams.append("page_size", pageSize.toString());
        }
        if (search !== undefined && search !== "") {
          queryParams.append("search", search);
        }
        return `/contact?${queryParams.toString()}`;
      },
      providesTags: ["CONTACT"],
    }),

    getContact: builder.query<IContactRecord, IContactRecord["id"]>({
      query: (contactId) => `/contact/${contactId}`,
      providesTags: ["CONTACT"],
    }),

    createContact: builder.mutation<IContactRecord, IContactRequest>({
      query: (contact) => ({
        url: `/contact`,
        method: "POST",
        body: contact,
      }),
      invalidatesTags: ["CONTACT"],
    }),

    updateContact: builder.mutation<IContactRecord, IContactRequest & { id: IContactRecord["id"] }>({
      query: ({ id, ...contact }) => ({
        url: `/contact/${id}`,
        method: "PUT",
        body: contact,
      }),
      invalidatesTags: ["CONTACT"],
    }),

    deleteContact: builder.mutation<void, Pick<IContactRecord, "id">>({
      query: (contactId) => ({
        url: `/contact/${contactId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["CONTACT"],
    }),

    getAutocomplete: builder.query<IAutocompleteResponse, { searchModel: string; params: IAutocompleteRequest }>({
      query: ({ searchModel, params }) => {
        const queryString = new URLSearchParams(Object.entries(params).filter(([_, v]) => v !== undefined)).toString();

        if (searchModel.startsWith("org::")) {
          const [, modelName] = searchModel.split("::");
          return `/organization/custom-autocomplete?model=${modelName}&${queryString}`;
        } else {
          return `/${searchModel}/autocomplete?${queryString}`;
        }
      },
      providesTags: ["PRODUCT", "CONTACT"],
    }),

    triggerSync: builder.mutation<{ message: string; status: string; task_id: string }, void>({
      query: () => ({
        url: `/organization/sync`,
        method: "POST",
      }),
      invalidatesTags: ["PRODUCT", "CONTACT"],
    }),

    getSyncStatus: builder.query<SyncStatus, { task_id: string }>({
      query: ({ task_id }) => `/organization/sync?task_id=${task_id}`,
    }),

    getGatewayEvents: builder.query<IListResponse<IGatewayEventRecord>, void>({
      query: () => `/organization/gateway/events`,
    }),

    startLogStreaming: builder.mutation<{ status: string; message: string }, void>({
      query: () => ({
        url: "/organization/gateway/start_log_streaming",
        method: "POST",
      }),
    }),

    stopLogStreaming: builder.mutation<{ status: string; message: string }, void>({
      query: () => ({
        url: "/organization/gateway/stop_log_streaming",
        method: "POST",
      }),
    }),

    getLogs: builder.query<{ status: string; logs: Array<{ timestamp: string; message: string }> }, void>({
      query: () => "/organization/gateway/get_logs",
    }),

    getBenchmarkReports: builder.query<IListResponse<IBenchmarkReportListItem>, ISearchRequest>({
      query: ({ page, pageSize, search }) => {
        const queryParams = new URLSearchParams();
        if (page !== undefined) {
          queryParams.append("page", page.toString());
        }
        if (pageSize !== undefined) {
          queryParams.append("page_size", pageSize.toString());
        }
        if (search !== undefined && search !== "") {
          queryParams.append("search", search);
        }
        return `/benchmark?${queryParams.toString()}`;
      },
      providesTags: ["BENCHMARK"],
    }),

    getBenchmarkReport: builder.query<IBenchmarkReportRecord, number>({
      query: (id) => `/benchmark/${id}`,
      providesTags: ["BENCHMARK"],
    }),

    createBenchmark: builder.mutation<void, IBenchmarkReportRequest>({
      query: (body) => ({
        url: "/benchmark",
        method: "POST",
        body,
      }),
      invalidatesTags: ["BENCHMARK"],
    }),

    deleteBenchmarkReport: builder.mutation<void, number>({
      query: (id) => ({
        url: `/benchmark/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["BENCHMARK"],
    }),

    //
    // Organizations
    //
    getOrganizations: builder.query<IListResponse<IOrganizationRead>, ISearchRequest>({
      query: () => {
        const queryParams = new URLSearchParams();
        return `/organization?${queryParams.toString()}`;
      },
      providesTags: ["ORGANIZATION"],
    }),

    getOrganization: builder.query<IOrganizationRead, IOrganizationRead["id"]>({
      query: (organizationId) => `/organization/${organizationId}`,
      providesTags: ["ORGANIZATION"],
    }),

    createOrganization: builder.mutation<IOrganizationRead, IOrganizationCreate>({
      query: (organization) => ({
        url: `/organization`,
        method: "POST",
        body: organization,
      }),
      invalidatesTags: ["ORGANIZATION"],
    }),

    updateOrganization: builder.mutation<IOrganizationRead, IOrganizationUpdate & { id: IOrganizationRead["id"] }>({
      query: ({ id, ...organization }) => ({
        url: `/organization/${id}`,
        method: "PUT",
        body: organization,
      }),
      invalidatesTags: ["ORGANIZATION"],
    }),

    deleteOrganization: builder.mutation<void, IOrganizationRead["id"]>({
      query: (organizationId) => ({
        url: `/organization/${organizationId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ORGANIZATION"],
    }),

    // NTE Code88 Plugin
    getNteCode88Items: builder.query<
      { items: Array<{ descr_forn: string; email: string; evaso: boolean; id: number; note: string; qta: number }> },
      string
    >({
      query: (workOrder) => `/plugins/nte/code88?work_order=${encodeURIComponent(workOrder)}`,
      providesTags: ["NTE_CODE88"],
    }),

    updateNteCode88Items: builder.mutation<
      void,
      { items: Array<{ descr_forn: string; email: string; evaso: boolean; id: number; note: string; qta: number }> }
    >({
      query: (body) => ({
        url: "/plugins/nte/code88",
        method: "POST",
        body,
      }),
      invalidatesTags: ["NTE_CODE88"],
    }),

    //
    // sheet import
    //

    triggerSheetImport: builder.mutation<{ message: string; status: string; task_id: string }, File>({
      query: (file) => {
        const formData = new FormData();
        formData.append("file", file);
        return {
          url: "/organization/sheet_import",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["PRODUCT", "CONTACT", "ORGANIZATION"],
    }),

    getSheetImportStatus: builder.query<SheetImportStatus, { task_id: string }>({
      query: ({ task_id }) => `/organization/sheet_import?task_id=${task_id}`,
    }),
  }),
});

export const {
  // Authz
  useGetPolicyQuery,

  // Admin and debug
  useDebugInfoQuery,

  // User
  useLoginMutation,
  useLogoutMutation,
  useCurrentUserQuery,
  useGetUserQuery,
  useGetUsersQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,

  // Emails
  useGetWorkflowEmailsQuery,
  useReprocessEmailMutation,
  useGetEmailQuery,
  usePatchEmailMutation,
  useGenerateCsvMutation,
  useGetEmailNavigationQuery,
  useDeleteEmailMutation,
  useCertifyEmailMutation,
  useRegisterEmailMutation,
  useUploadEmailMutation,

  // Upload
  useUploadFileMutation,

  // Product
  useGetProductsQuery,
  useLazyGetProductsQuery,
  useLazyGetAutocompleteQuery,
  useGetProductQuery,
  useCreateProductMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,
  useLazyGetProductQuery,

  // Address
  useGetAddressesQuery,
  useGetAddressQuery,
  useCreateAddressMutation,
  useUpdateAddressMutation,
  useDeleteAddressMutation,

  // Contact
  useGetContactsQuery,
  useLazyGetContactsQuery,
  useGetContactQuery,
  useCreateContactMutation,
  useUpdateContactMutation,
  useDeleteContactMutation,

  // Sync
  useTriggerSyncMutation,
  useGetSyncStatusQuery,

  // Gateway
  useGetGatewayEventsQuery,
  useStartLogStreamingMutation,
  useStopLogStreamingMutation,
  useGetLogsQuery,

  // Benchmark
  useGetBenchmarkReportsQuery,
  useGetBenchmarkReportQuery,
  useCreateBenchmarkMutation,
  useDeleteBenchmarkReportMutation,

  // Workflow
  useGetWorkflowsQuery,
  useGetWorkflowQuery,
  useCreateWorkflowMutation,
  useUpdateWorkflowMutation,
  useDeleteWorkflowMutation,

  // Organization
  useGetOrganizationsQuery,
  useGetOrganizationQuery,
  useCreateOrganizationMutation,
  useDeleteOrganizationMutation,
  useUpdateOrganizationMutation,

  // Sheet Import
  useTriggerSheetImportMutation,
  useGetSheetImportStatusQuery,

  // NTE Code88
  useGetNteCode88ItemsQuery,
  useUpdateNteCode88ItemsMutation,
} = appApi;
