import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import { Tooltip } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRegisterEmailMutation } from "../../../../../app/services/appApi";
import { IEmailRecord } from "../../../emailTypes";

interface RegisterEmailBtnProps extends React.ComponentProps<typeof LoadingButton> {
  email: IEmailRecord;
  disable?: boolean;
}

function RegisterEmailBtn({ email, disable, ...props }: RegisterEmailBtnProps) {
  const { t } = useTranslation();

  const [registerEmail, { isLoading, isSuccess, isError }] = useRegisterEmailMutation();

  //if the enable.register is equals to  0, the button will be disabled, otherwise it will be enabled
  const hasGateway = email.workflow?.enable_register;
  const disabled = !hasGateway || email.is_registered || disable || !email.is_certified;

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(t("email.dataSendSuccess"), { variant: "success" });
    }
    if (isError) {
      enqueueSnackbar(t("email.dataSendFailure"), { variant: "error" });
    }
  }, [isSuccess, isError, t]);

  return (
    <Tooltip title={!hasGateway ? t("email.noGatewayError") : t("email.sendDataToErpOrGateway")}>
      <span>
        <LoadingButton
          variant="contained"
          color="primary"
          startIcon={<SendIcon />}
          {...props}
          onClick={() => registerEmail({ id: email.id })}
          loading={isLoading}
          disabled={disabled}
        >
          {t("common.send")}
        </LoadingButton>
      </span>
    </Tooltip>
  );
}

export default RegisterEmailBtn;
