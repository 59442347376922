import { TFunction } from "i18next";
import { UseFormReturn } from "react-hook-form";
import { IEmailRecord } from "../../../emailTypes";
import nteCode88Plugin from "./nteCode88Plugin";

export interface TabPlugin {
  id: string; // a unique plugin id (for example, "customAnalytics")
  label: string; // the tab label (e.g. "Analytics")
  priority: number; // tab priority (higher numbers appear first, e.g. 0, -10, -20)
  // The render function receives the form instance so that the plugin can read/modify the form data
  render: (form: UseFormReturn<any>, email: IEmailRecord, t: TFunction) => JSX.Element;

  // callback that returns true if this plugin should be rendered for the given workflow:
  shouldLoad: (workflowName: string) => boolean;
}

export function getTabPlugins(workflowName: string): TabPlugin[] {
  return [
    // vvvvvvv Import the plugins here vvvvvvv
    nteCode88Plugin,
  ].filter((plugin) => plugin.shouldLoad(workflowName));
}
