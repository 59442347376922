import { SearchUISchemaField } from "@features/email/emailDetailPage/tabs/fields/dynamicForm/dynamicFormTypes";
import Autocomplete, { AutocompleteRenderOptionState } from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { debounce } from "@mui/material/utils";
import React, { forwardRef, HTMLAttributes, useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { IAutocompleteOption, IAutocompleteRequest, IAutocompleteResponse } from "./ServerSideSearchSelect2";
import { Checkbox } from "@mui/material";

interface Props {
  advancedSearchParameters?: SearchUISchemaField["advancedSearchParameters"];
  disabled?: boolean;
  inputProps?: React.ComponentProps<typeof TextField>;
  label: string;
  lazyQuery: any;
  name: string;
  onBlur: () => void;
  onChange: (value: string[]) => void;
  searchModel: string;
  value: string[];
}

const ServerSideSearchSelectMultiple = forwardRef<HTMLDivElement, Props>(
  (
    {
      advancedSearchParameters,
      disabled,
      inputProps,
      label,
      lazyQuery,
      name,
      onBlur,
      onChange: onChangeProps,
      searchModel,
      value,
    },
    ref,
  ) => {
    const form = useFormContext();
    const extraParameterValues = form.watch(advancedSearchParameters?.map((x) => `${x.valueFromField}.value`) ?? []);

    const [extraParameters, setExtraParameters] = useState<Record<string, any>>(
      extraParameterValues.reduce(
        (acc, curr, index) => {
          if (curr) {
            acc[advancedSearchParameters![index].key] = curr;
          }

          return acc;
        },
        {} as Record<string, any>,
      ),
    );
    const [selectedOptions, setSelectedOptions] = useState<IAutocompleteOption[]>([]);
    const [inputValue, setInputValue] = useState("");

    const [trigger, { data, isFetching, isUninitialized }] = lazyQuery();

    const debouncedTrigger = debounce(trigger, 300);

    const options = useMemo(() => {
      const typedResults = (data?.results ?? []) as IAutocompleteOption[];
      const uniqueResults = typedResults.filter(
        (option, index, arr) => index === arr.findIndex((t) => t.id === option.id),
      );

      return uniqueResults;
    }, [data?.results]);

    // Update extraParameters only when extraParametersValues values change
    useEffect(() => {
      const newExtraParameters = extraParameterValues.reduce(
        (acc, curr, index) => {
          if (curr) {
            acc[advancedSearchParameters![index].key] = curr;
          }

          return acc;
        },
        {} as Record<string, any>,
      );

      if (JSON.stringify(extraParameters) !== JSON.stringify(newExtraParameters)) {
        setExtraParameters(newExtraParameters);
        trigger({ searchModel, params: { ...newExtraParameters, search: inputValue } });
      }
    }, [advancedSearchParameters, extraParameterValues, extraParameters, inputValue, searchModel, trigger]);

    // When component mounts, if value is not empty, fetch the selected options
    useEffect(() => {
      if (value && value.length > 0) {
        trigger({ searchModel, params: { id: value.join(","), ...extraParameters, search: "" } });
      } else {
        trigger({ searchModel, params: { ...extraParameters, search: "" } });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (value === undefined || value.length === 0) {
        if (selectedOptions.length > 0) {
          setSelectedOptions([]);
        }

        return;
      }

      const remainingSelectedOptions = selectedOptions.filter((option) => value.includes(option.id));

      const foundSelectedOptions = value
        .map((selectedValue) => options.find((x) => x.id.toString() === selectedValue.toString()))
        .filter(Boolean) as IAutocompleteOption[];

      if (
        (remainingSelectedOptions && remainingSelectedOptions.length > 0) ||
        (foundSelectedOptions && foundSelectedOptions.length > 0)
      ) {
        const newSelectedOptions = [...remainingSelectedOptions, ...foundSelectedOptions].filter(
          (option, index, self) => index === self.findIndex((t) => t.id.toString() === option.id.toString()),
        );

        const selectedOptionsChanged =
          newSelectedOptions
            .map((option) => option.id)
            .sort()
            .join(",") !==
          selectedOptions
            .map((option) => option.id)
            .sort()
            .join(",");
        const valueChanged =
          newSelectedOptions
            .map((option) => option.id)
            .sort()
            .join(",") !== value.sort().join(",");

        if (selectedOptionsChanged) {
          setSelectedOptions(newSelectedOptions);
        }

        if (valueChanged) {
          onChangeProps(
            newSelectedOptions.map((option) => option.id)
          );
        }
      } else if (!isUninitialized && !isFetching) {
        // This is the case where the pre-selected option is not found (e.g. deleted from the database)
        setSelectedOptions([]);
        onChangeProps([]);
      }
    }, [value, options, selectedOptions, isFetching, onChangeProps, isUninitialized]);

    const handleInputChange = useCallback(
      (_: any, value: string) => {
        setInputValue(value);
        debouncedTrigger({ searchModel, params: { ...extraParameters, search: value } });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [searchModel, extraParameters],
    );

    const handleChange = useCallback(
      (_: any, selectedOptions: IAutocompleteOption[]) => {
        if (selectedOptions && selectedOptions.length > 0) {
          onChangeProps(
            selectedOptions.map((option) => option.id)
          );
        } else {
          onChangeProps([]);
        }
      },
      [onChangeProps],
    );

    const renderOption = useCallback(
      (
        props: HTMLAttributes<HTMLLIElement>,
        option: IAutocompleteOption,
        { selected }: AutocompleteRenderOptionState,
      ) => (
        <li {...props} key={`listItem-${option.id}`}>
          <Checkbox checked={selected} />
          <Box>
            <Typography variant="body1">{option.option_text}</Typography>
            {option.option_details &&
              option.option_details.map((val) => (
                <Typography variant="caption" key={val} display="block">
                  {val}
                </Typography>
              ))}
          </Box>
        </li>
      ),
      [],
    );

    return (
      <Autocomplete
        disableCloseOnSelect
        multiple
        ref={ref}
        disabled={disabled}
        filterOptions={(x) => x}
        getOptionLabel={(option) => option.option_text?.toString()}
        inputValue={inputValue}
        isOptionEqualToValue={(option, value) => option.id.toString() === value.id.toString()}
        loading={isFetching}
        noOptionsText="Nessun risultato"
        onBlur={onBlur}
        onChange={handleChange}
        onInputChange={handleInputChange}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            {...inputProps}
            disabled={disabled || inputProps?.disabled}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            label={label}
            name={name}
          />
        )}
        renderOption={renderOption}
        value={selectedOptions}
      />
    );
  },
);

export default ServerSideSearchSelectMultiple;
