import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { Allotment } from "allotment";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import "react18-json-view/src/style.css";
import { useGetEmailQuery, usePatchEmailMutation } from "../../../app/services/appApi";
import { withAuth } from "../../auth/authWrappers";
import EmailToolbar from "../shared/EmailToolbar";
import ErrorPlaceholder from "./ErrorPlaceholder";
import LoadingPlaceholder from "./LoadingPlaceholder";
import EmailTabsColumn from "./tabs/EmailTabsColumn";
import EmailContent from "./viewer/EmailViewerColumn";
import { HighlightAreaWithLabel } from "./viewer/PDFViewer";

export interface HighlightAreaWithLabelAndId extends HighlightAreaWithLabel {
  tabID: string;
}

function EmailDetailPage() {
  const { t } = useTranslation();
  const { id, workflowId } = useParams<{ id: string; workflowId: string }>();

  const [page_idx, setPageIdx] = useState<number>(0);
  const [tabID, setTabID] = useState<string | null>(null);
  const [src_ref, setSrcRef] = useState<{ type: string; id: string }>({ type: "email", id: "" });
  const [activeArea, setActiveArea] = useState<HighlightAreaWithLabelAndId | null>(null);

  const { data: email, isLoading, isFetching, isError } = useGetEmailQuery(Number(id), { skip: !id });
  const [patchEmail, { isLoading: isPatching, isSuccess: isPatchSuccess, isError: isPatchError }] =
    usePatchEmailMutation();

  useEffect(() => {
    if (isPatchSuccess) {
      enqueueSnackbar(t("common.dataUpdateSuccess"), { variant: "success" });
    }
    if (isPatchError) {
      enqueueSnackbar(t("common.dataUpdateFailure"), { variant: "error" });
    }
  }, [isPatchSuccess, isPatchError, t]);

  const onSubmit = (data: any) => {
    if (id) {
      patchEmail({ id: Number(id), body: { parsed_info: data } });
    }
  };

  const handleTarget = (target: HighlightAreaWithLabelAndId) => {
    setActiveArea(target);
    setPageIdx(target.pageIndex);
    setTabID(target.tabID);
  };

  const handleAttachmentChange = (_: React.SyntheticEvent, newValue: string) => {
    setTabID(newValue);
    setSrcRef(newValue === "email" ? { type: "email", id: "" } : { type: "attachment", id: newValue.split("::")[1] });
  };

  useEffect(() => {
    if (email) {
      setTabID((prevTabID) => {
        if (prevTabID === null) {
          return email.attachments.length > 0 ? `attachment::${email.attachments[0].id}` : "email";
        } else if (prevTabID === "email") {
          return "email";
        } else {
          return email.attachments.length > 0 ? `attachment::${email.attachments[0].id}` : "email";
        }
      });
    }
  }, [email]);

  if (isLoading) {
    return <LoadingPlaceholder />;
  }

  if (isError) {
    return <ErrorPlaceholder />;
  }

  if (!email) {
    return null;
  }

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <IconButton component={Link} to={`/workflow/${workflowId}`} sx={{ mr: 2 }}>
          <ArrowBackIcon />
        </IconButton>
        <Box sx={{ flexGrow: 1, mb: 2, width: "100px" }}>
          <Typography variant="h5" component="h1" noWrap>
            <b>{email.subject === "" ? t("email.emailWithoutSubject") : (email.subject ?? t("email.loadingEmail"))}</b>
          </Typography>
        </Box>
        {id && <EmailToolbar emailId={Number(id)} email={email} />}
      </Stack>
      <Allotment
        onDragEnd={(sizes) => localStorage.setItem("emailDetailSplit", JSON.stringify(sizes))}
        defaultSizes={JSON.parse(localStorage.getItem("emailDetailSplit") || "[0.66,0.33]")}
      >
        <EmailContent
          email={email}
          tabID={tabID || "email"}
          handleAttachmentChange={handleAttachmentChange}
          activeArea={activeArea}
        />
        <EmailTabsColumn
          email={email}
          isPatching={isPatching}
          isFetching={isFetching}
          onSubmit={onSubmit}
          handleTarget={handleTarget}
          page_idx={page_idx}
          tabID={tabID || "email"}
          src_ref={src_ref}
        />
      </Allotment>
    </>
  );
}

export default withAuth(EmailDetailPage);
