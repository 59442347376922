import { IProductRecord } from "@features/product/productTypes";
import { BelliniFormData, BelliniValidationData } from "./belliniValidationTypes";

function validateQuantity(quantity: number, product: IProductRecord): string | undefined {
  if (!product.unit_size) {
    alert("Internal error: product unit size is missing. Validation skipped.");
    console.error("Product unit size is missing", product);
    return;
  }

  if (product.unit_measure === "KG" && !Number.isInteger(quantity / product.unit_size)) {
    // Unit measure is KG, thus the quantity must be a multiple of the unit size
    return "La quantità deve essere un multiplo della dimensione della confezione.";
  }
  if (product.unit_measure === "NR" && !Number.isInteger(quantity)) {
    // Unit measure is NR, thus the quantity must be an integer
    return "La quantità deve essere un numero intero.";
  }
}

export async function validateBelliniData(
  formData: BelliniFormData,
  validationData: BelliniValidationData,
): Promise<{
  isValid: boolean;
  errors?: Record<string, string>;
}> {
  const errors: Record<string, string> = {};

  // For each pair in (formData.items[:index].quantity, validationData.items[:index]), check if the quantity is valid
  const quantities = (formData?.items || []).map((item) => Number(item.quantity.value));
  const productCatalog = validationData.productCatalog || [];

  // Check if they are the same length. if not, do not validate
  if (quantities.length !== productCatalog.length) {
    alert("Internal error: product catalog length does not match form data length. Validation skipped.");
    console.error("Product catalog length does not match form data length", quantities, productCatalog);
    return { isValid: true };
  }

  for (let i = 0; i < quantities.length; i++) {
    const quantity = quantities[i];
    const product = productCatalog[i];
    if (!product) {
      alert("Internal error: product not found in catalog. Validation skipped.");
      console.error("Product not found in catalog", quantities, productCatalog);
      return { isValid: true };
    }

    const error = validateQuantity(quantity, product);
    if (error) {
      errors[`items[${i}].quantity.value`] = error;
    }
  }

  return {
    isValid: Object.keys(errors).length === 0,
    errors: Object.keys(errors).length > 0 ? errors : undefined,
  };
}
