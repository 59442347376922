import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";

const schema = Joi.defaults((schema) =>
  schema.options({
    allowUnknown: true,
  }),
)
  .object({
    port_type_id: Joi.object({
      value: Joi.string().required(),
    }),
    order_number: Joi.object({
      value: Joi.string().required(),
    }),
    order_date: Joi.object({
      value: Joi.string().isoDate().required(),
    }),
    buyer_id: Joi.object({
      value: Joi.alternatives().try(Joi.number().integer(), Joi.string()).required(),
    }),
    order_type: Joi.object({
      value: Joi.string().required(),
    }),
    payment_terms_id: Joi.object({
      value: Joi.string().required(),
    }),
    shipping_destination_id: Joi.object({
      value: Joi.optional(),
    }),
    shipping_type: Joi.object({
      value: Joi.string().optional(),
    }),
    notes: Joi.object({
      value: Joi.string().optional().min(0).max(500),
    }),

    items: Joi.array().items(
      Joi.object({
        product_id: Joi.object({
          value: Joi.alternatives().try(Joi.number().integer(), Joi.string()).required(),
        }),

        quantity: Joi.object({
          value: Joi.number().positive().required().messages({
            "number.base": "La quantità deve essere un numero.",
            "number.positive": "La quantità deve essere un numero positivo.",
            "any.required": "La quantità è obbligatoria.",
          }),
        }),
        unit_price: Joi.object({
          value: Joi.number().positive().required(),
        }),
        delivery_date: Joi.object({
          value: Joi.string().isoDate().required(),
        }),
        notes: Joi.object({
          value: Joi.string().min(0).max(40).optional(),
        }),
        reserve_code: Joi.object({
          value: Joi.string().min(0).max(10).optional(),
        }),
        reserve_note: Joi.object({
          value: Joi.string().min(0).max(60).optional(),
        }),
      }),
    ),
  })
  .messages({
    "object.base": "Il valore deve essere un oggetto.",
    "object.unknown": "Il campo non è valido.",
    "object.required": "Il campo è obbligatorio.",
    "array.base": "Il valore deve essere un array.",
    "array.required": "Il campo è obbligatorio.",
    "string.base": "Il valore deve essere una stringa.",
    "string.empty": "Il campo non può essere vuoto.",
    "string.required": "Il campo è obbligatorio.",
    "string.min": "Il campo deve essere di almeno {#limit} caratteri.",
    "string.max": "Il campo deve essere di massimo {#limit} caratteri.",
    "number.base": "Il valore deve essere un numero.",
    "number.positive": "Il valore deve essere un numero positivo.",
    "number.integer": "Il valore deve essere un numero intero.",
    "number.required": "Il campo è obbligatorio.",
    "date.base": "Il valore deve essere una data.",
    "date.isoDate": "Il valore deve essere una data valida.",
    "date.required": "Il campo è obbligatorio.",
    "alternatives.match": "Il campo non è valido.",
    "alternatives.types": "Il campo non è valido.",
    "any.required": "Il campo è obbligatorio.",
    "any.only": "Il campo non è valido.",
    "any.invalid": "Il campo non è valido.",
    "any.custom": "Il campo non è valido.",
    "any.allowOnly": "Il campo non è valido.",
    "any.allowUnknown": "Il campo non è valido.",
    "any.unknown": "Il campo non è valido.",
    "any.empty": "Il campo non può essere vuoto.",
    "any.invalid": "Il campo non è valido.",
    "any.required": "Il campo è obbligatorio.",
    "any.allowOnly": "Il campo non è valido.",
    "any.allowUnknown": "Il campo non è valido.",
    "any.unknown": "Il campo non è valido.",
    "any.empty": "Il campo non può essere vuoto.",
    "any.invalid": "Il campo non è valido.",
    "any.required": "Il campo è obbligatorio.",
    "any.allowOnly": "Il campo non è valido.",
    "any.allowUnknown": "Il campo non è valido.",
    "any.unknown": "Il campo non è valido.",
    "any.empty": "Il campo non può essere vuoto.",
    "any.invalid": "Il campo non è valido.",
    "any.required": "Il campo è obbligatorio.",
    "string.isoDate": "Il valore deve essere una data valida.",
  });

export default joiResolver(schema);
