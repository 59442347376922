import MoreVertIcon from "@mui/icons-material/MoreVert";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/system/Stack";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FieldValues, FormProvider, Path, useForm, UseFormReturn } from "react-hook-form";
import CertifyEmailBtn from "./CertifyEmailBtn";
import RegisterEmailBtn from "./RegisterEmailBtn";
import RegisteredEmailAlert from "./RegisteredEmailAlert";
import DynamicEmailForm from "./dynamicForm/DynamicEmailForm";
import { EmailViewer } from "./dynamicForm/DynamicFieldRenderer";
import { UISchema, UISchemaField } from "./dynamicForm/dynamicFormTypes";
import { getFormValidationPlugins } from "./dynamicForm/plugins/externalValidationPluginRegistry";
import { EmailParsedInfo, IEmailRecord } from "../../../emailTypes";
import { ExternalValidationPlugin } from "./dynamicForm/plugins/externalValidationTypes";
import { useTranslation } from "react-i18next";

export interface EmailEditableFieldsProps {
  email: IEmailRecord;
  isLoading: boolean;
  onSubmit: (data: IEmailRecord) => void;
  onTarget: (target: EmailViewer) => void;
  page_idx: number;
  tabID: string;
  // New callbacks for reporting the form instance and dirty state.
  onFormInit?: (form: UseFormReturn<any>) => void;
  onDirtyChange?: (isDirty: boolean) => void;
}

const getFieldSchema = (uiSchema: UISchema, field: string, insideItems = false): UISchemaField | null => {
  if (insideItems) {
    return uiSchema.items.fields[field];
  }

  const groupName = Object.keys(uiSchema).find((groupName) => uiSchema[groupName].fields[field] !== undefined);

  if (!groupName) {
    return null;
  }

  return uiSchema[groupName].fields[field];
};

const sanitizeParsedInfo: (
  parsedInfo: EmailParsedInfo,
  uiSchema: UISchema,
  insideItems?: boolean,
) => EmailParsedInfo = (parsedInfo, uiSchema, insideItems = false) => {
  const sanitized: EmailParsedInfo = {};

  Object.keys(parsedInfo).forEach((key) => {
    // Manages at most 1 recursion level
    if (key === "items" && !insideItems) {
      const items = parsedInfo.items;

      if (!items) {
        return;
      }

      sanitized.items = items.map((item) => sanitizeParsedInfo(item, uiSchema, true));
    } else {
      sanitized[key] = { ...parsedInfo[key] };
      const value = parsedInfo[key].value;

      if (value === null || value === undefined) {
        return;
      }

      const schema = getFieldSchema(uiSchema, key, insideItems);

      if (!schema) {
        return;
      }

      switch (schema.type) {
        case "string":
        case "multiline": {
          sanitized[key].value = String(value);
          break;
        }
        case "int":
        case "float": {
          sanitized[key].value = !isNaN(Number(value)) ? Number(value) : null;
          break;
        }
        case "enum": {
          if (schema.multiple) {
            sanitized[key].value = Array.isArray(value)
              ? value.filter(Boolean).map(String)
              : typeof value === "string"
                ? [value]
                : [];

            // Check if the values are in the options
            sanitized[key].value = (sanitized[key].value as string[]).filter(
              (v: string) => schema.options.find((option: { value: any }) => option.value.toString() === v) !== undefined,
            );
          } else {
            sanitized[key].value = String(value);

            // Check if the value is in the options
            sanitized[key].value = schema.options.find((option: { value: any }) => option.value.toString() === sanitized[key].value)
              ? sanitized[key].value
              : null;
          }
          break;
        }
        case "search": {
          if (schema.multiple) {
            sanitized[key].value = Array.isArray(value)
              ? value.filter(Boolean).map(String)
              : typeof value === "string"
                ? [value]
                : [];
          } else {
            sanitized[key].value = String(value);
          }
          break;
        }
        case "date": {
          // TODO: We should also validate date formats
          break;
        }
      }
    }
  });

  return sanitized;
};

const EmailFieldsTab: React.FC<EmailEditableFieldsProps> = ({
  email,
  isLoading,
  onSubmit,
  onTarget,
  page_idx,
  tabID,
  onFormInit,
  onDirtyChange,
}) => {
  const { t } = useTranslation();
  const [validationPlugins, setValidationPlugins] = useState<ExternalValidationPlugin[]>([]);
  const [isValidating, setIsValidating] = useState(false);

  useEffect(() => {
    const plugins = getFormValidationPlugins(email.workflow?.name || '');
    setValidationPlugins(plugins);
  }, [email.workflow?.name]);

  const form = useForm<any>({
    defaultValues: sanitizeParsedInfo(email.parsed_info, email.ui_schema),
    resolver: async (values, context, options) => {
      let errors = {};
      for (const plugin of validationPlugins) {
        if (plugin.getResolver) {
          const resolver = plugin.getResolver();
          if (resolver) {
            const result = await resolver(values, context, options);
            errors = { ...errors, ...result.errors };
          }
        }
      }
      return { values, errors };
    },
  });

  useEffect(() => {
    // Reset the form when a new email is loaded.
    form.reset(sanitizeParsedInfo(email.parsed_info, email.ui_schema));
  }, [email, form]);

  // Report the form instance (so that parent or plugin tabs can access it)
  useEffect(() => {
    if (onFormInit) {
      onFormInit(form);
    }
  }, [form, onFormInit]);

  // Report the dirty state upward so that EmailTabsColumn can warn on tab change.
  useEffect(() => {
    if (onDirtyChange) {
      onDirtyChange(form.formState.isDirty);
    }
  }, [form.formState.isDirty, onDirtyChange]);

  const [isDebug, setIsDebug] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleError = (errors: any) => {
    enqueueSnackbar(t("email.formError"), { variant: "error" });
    console.error("Validation error: ", errors);
  };

  const disableAll = email.is_registered || isLoading;

  const handleSubmit = async (data: any) => {
    setIsValidating(true);
    try {
      for (const plugin of validationPlugins) {
        if (plugin.validate) {
          const result = await plugin.validate(data);
          if (!result.isValid) {
            Object.entries(result.errors || {}).forEach(([field, message]) => {
              form.setError(field as Path<FieldValues>, { type: 'manual', message });
            });
            enqueueSnackbar("Ci sono errori nel form", { variant: "error" });
            return;
          }
        }
      }
      onSubmit(data);
    } finally {
      setIsValidating(false);
    }
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(handleSubmit, handleError)}
        style={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <Box
          sx={{
            overflowY: "auto",
            flexGrow: 1,
            p: 1,
          }}
        >
          {email.is_registered && <RegisteredEmailAlert sx={{ mb: 2 }} />}
          <DynamicEmailForm
            uiSchema={email.ui_schema}
            email={email}
            control={form.control}
            disabled={disableAll}
            onTarget={onTarget}
            page_idx={page_idx}
            tabID={tabID}
          />
        </Box>
        <Stack direction={{ xs: "column", sm: "row" }} gap={1} sx={{ mt: 2 }} justifyContent="flex-end">
          <Box sx={{ flexGrow: 1 }}>
            <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
          </Box>
          <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={isDebug} onChange={() => setIsDebug(!isDebug)} />}
                  label={t("email.showAdvancedData")}
                />
              </FormGroup>
            </MenuItem>
          </Menu>
          <Tooltip title={t("common.saveData")}>
            <span>
              <LoadingButton
                type="submit"
                loading={isLoading || isValidating}
                disabled={email.is_registered || isValidating}
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
              >
                {isValidating ? t("common.validating") : t("commonn.save")}
              </LoadingButton >
            </span >
          </Tooltip >
          <CertifyEmailBtn email={email} /> {/* disable={form.formState.isDirty} /> */}
          <RegisterEmailBtn email={email} /> {/* disable={form.formState.isDirty} /> //TODO: re-enable */}
        </Stack >
      </form >
    </FormProvider >
  );
};

export default EmailFieldsTab;
