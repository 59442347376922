import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
const resources = {
  en: {
    translation: require("./langs/en.json"),
  },
  it: {
    translation: require("./langs/it.json"),
  },
};

const storedLng = localStorage.getItem("lng"); // Get the language from local storage

// eslint-disable-next-line import/no-named-as-default-member
i18n.use(initReactI18next).init({
  resources,
  lng: storedLng || "it",
  interpolation: {
    escapeValue: false,
  },
});

i18n.services.formatter?.add("lowercase", (value, lng, options) => {
  return value.toLowerCase();
});

export default i18n;
