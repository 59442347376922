import AddIcon from "@mui/icons-material/Add";
import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import { Control, useFieldArray, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IEmailRecord } from "../../../../emailTypes";
import { EmailDetailAccordion } from "../EmailDetailAccordion";
import { ItemActionsToolbar } from "../ItemActions";
import DynamicFieldRenderer, { EmailViewer } from "./DynamicFieldRenderer";
import VerifiedBadge from "./VerifiedBadge";
import { UISchema } from "./dynamicFormTypes";

interface DynamicEmailFormProps {
  uiSchema: UISchema;
  email: IEmailRecord;
  control: Control<any>;
  disabled?: boolean;
  onTarget: (target: EmailViewer) => void;
  page_idx: number;
  tabID: string;
}
const DynamicEmailForm: React.FC<DynamicEmailFormProps> = ({
  uiSchema,
  email,
  control,
  disabled,
  onTarget,
  page_idx,
  tabID,
}) => {
  const { t } = useTranslation();

  const fieldMethods = useFieldArray({
    control,
    name: "items",
  });

  const { fields, append } = fieldMethods;

  const handleAddItem = () => {
    append({});
  };

  // Sort groups by priority
  const sortedGroups = Object.entries(uiSchema).sort((a, b) => b[1].priority - a[1].priority);

  // Watch the entire items array
  const items = useWatch({
    control,
    name: "items",
  });

  return (
    <>
      {sortedGroups.map(([groupName, group]) => {
        if (groupName === "items") {
          // Sort item fields by priority
          const sortedItemFields = Object.entries(group.fields).sort((a, b) => b[1].priority - a[1].priority);

          return (
            <React.Fragment key={groupName}>
              {fields.map((item, index) => {
                const itemValidationCheck = items?.[index]?.validation_checks;

                return (
                  <EmailDetailAccordion
                    key={item.id}
                    id={`item-${index}`}
                    label={
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant="subtitle1">{t("email.rowNumber", { number: index + 1 })}</Typography>
                        <VerifiedBadge
                          tooltip={t("email.rowVerified")}
                          status={itemValidationCheck?.status}
                          message={itemValidationCheck?.message}
                        />
                      </Stack>
                    }
                  >
                    <Stack spacing={2}>
                      {sortedItemFields.map(([fieldName, fieldSchema]) => (
                        <DynamicFieldRenderer
                          key={fieldName}
                          fieldName={fieldName}
                          fieldSchema={fieldSchema}
                          control={control}
                          index={index}
                          disabled={disabled}
                          onTarget={onTarget}
                          page_idx={page_idx}
                          tabID={tabID}
                        />
                      ))}
                      <ItemActionsToolbar
                        fieldArrayMethods={fieldMethods}
                        index={index}
                        item={item}
                        disabled={disabled}
                      />
                    </Stack>
                  </EmailDetailAccordion>
                );
              })}
              <Button
                fullWidth
                color="primary"
                disabled={disabled}
                onClick={handleAddItem}
                size="small"
                startIcon={<AddIcon fontSize="inherit" />}
                sx={{ my: 2 }}
                variant="outlined"
              >
                {t("email.addRow")}
              </Button>
            </React.Fragment>
          );
        }

        // Sort fields by priority
        const sortedFields = Object.entries(group.fields).sort((a, b) => b[1].priority - a[1].priority);

        return (
          <EmailDetailAccordion key={groupName} id={groupName} label={group.title}>
            <Stack spacing={2}>
              {sortedFields.map(([fieldName, fieldSchema]) => (
                <DynamicFieldRenderer
                  key={fieldName}
                  fieldName={fieldName}
                  fieldSchema={fieldSchema}
                  control={control}
                  disabled={disabled}
                  onTarget={onTarget}
                  page_idx={page_idx}
                  tabID={tabID}
                />
              ))}
            </Stack>
          </EmailDetailAccordion>
        );
      })}
    </>
  );
};

export default DynamicEmailForm;
