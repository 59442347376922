import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { IconButton, Stack, Tooltip } from "@mui/material";
import React from "react";
import { UseFieldArrayReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface ItemActionsToolbarProps<T> {
  fieldArrayMethods: UseFieldArrayReturn<any, "items", "id">;
  index: number;
  item: T;
  disabled?: boolean;
}

const ItemActionsToolbar: React.FC<ItemActionsToolbarProps<any>> = ({
  fieldArrayMethods: { fields, move, insert, remove },
  index,
  item,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <Stack direction={{ sm: "column", md: "row" }} spacing={2} justifyContent="space-around">
      <Tooltip disableHoverListener={disabled || index === 0} title={t("email.moveRowUp")}>
        <div>
          <IconButton
            disabled={disabled || index === 0}
            onClick={() => move(index, index - 1)}
            size={"small"}
            tabIndex={-1}
          >
            <ArrowUpwardIcon fontSize="inherit" />
          </IconButton>
        </div>
      </Tooltip>
      <Tooltip disableHoverListener={disabled || index === fields.length - 1} title={t("email.moveRowDown")}>
        <div>
          <IconButton
            disabled={disabled || index === fields.length - 1}
            onClick={() => move(index, index + 1)}
            size={"small"}
            tabIndex={-1}
          >
            <ArrowDownwardIcon fontSize="inherit" />
          </IconButton>
        </div>
      </Tooltip>
      <Tooltip title={t("email.duplicateRow")}>
        <div>
          <IconButton disabled={disabled} onClick={() => insert(index + 1, { ...item })} size={"small"} tabIndex={-1}>
            <FileCopyIcon fontSize="inherit" />
          </IconButton>
        </div>
      </Tooltip>
      <Tooltip title={t("email.deleteRow")}>
        <div>
          <IconButton disabled={disabled} onClick={() => remove(index)} size={"small"} tabIndex={-1}>
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </div>
      </Tooltip>
    </Stack>
  );
};

export { ItemActionsToolbar };
