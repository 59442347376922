import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { Box, Button, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/system/Stack";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDeleteWorkflowMutation, useGetWorkflowsQuery } from "../../app/services/appApi";
import { withAuth } from "../auth/authWrappers";
import { IWorkflowRead } from "../auth/types";
import WorkflowFormDialog from "./WorkflowFormDialog";


const WorkflowAdminListPage = () => {
  const navigate = useNavigate();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 20,
    page: 0,
  });

  const [searchValue, setSearchValue] = useState("");
  const { data, isLoading } = useGetWorkflowsQuery({
    page: paginationModel.page + 1,
    pageSize: paginationModel.pageSize,
    search: searchValue,
    show_all: true,
  });

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      width: 180,
    },
    {
      field: "code",
      headerName: "Code",
      width: 180,
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
    },
    {
      field: "edit",
      headerName: "",
      //@ts-ignore-next-line
      renderCell: (params: GridValueGetterParams) => <WorkflowFormDialog workflowId={params.row.id} />,
    },
    {
      field: "delete",
      headerName: "",
      //@ts-ignore-next-line
      renderCell: (params: GridValueGetterParams) => <WorkflowDeleteBtn workflowId={params.row.id} />,
    },
  ];

  return (
    <>
      <Stack justifyContent="space-between" gap={2} direction="row" sx={{ mb: 2, alignItems: "center" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <ManageAccountsIcon />
          <Typography variant="h5" component="h1" noWrap>
            <b> Flussi di lavoro</b>
          </Typography>
        </Box>
        <Stack direction="row" gap={2} alignItems="center">
          <WorkflowFormDialog
            render={(props) => (
              <Button startIcon={<AddIcon />} variant="contained" color="primary" {...props}>
                Aggiungi
              </Button>
            )}
          />
        </Stack>
      </Stack>

      <DataGrid
        disableRowSelectionOnClick
        rows={data?.results || []}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
        pageSizeOptions={[20, 50, 100]}
        sx={{ flex: 1 }}
        loading={isLoading}
        paginationMode="server"
        rowCount={data?.count || 0}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
      />
    </>
  );
};

const WorkflowDeleteBtn: React.FC<{ workflowId: IWorkflowRead["id"] }> = ({ workflowId }) => {
  const [triggerDelete, { isLoading, isError, isSuccess }] = useDeleteWorkflowMutation();
  const { t } = useTranslation();

  // Handle error and success notifications
  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(t("Model deleted", { model: "Workflow", count: 1 }), { variant: "success" });
    }
    if (isError) {
      enqueueSnackbar(t("Model deletion failed", { model: "Workflow", count: 1 }), { variant: "error" });
    }
  }, [isSuccess, isError, t]);

  return (
    <IconButton onClick={() => triggerDelete(workflowId)} color="error" disabled={isLoading}>
      <DeleteOutlineOutlinedIcon />
    </IconButton>
  );
};

export default withAuth(WorkflowAdminListPage);
