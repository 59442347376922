import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import { IEmailRecord } from "../emailTypes";
import DeleteEmailBtn from "./DeleteEmailBtn";
import DownloadCsvBtn from "./DownloadCsvBtn";
import EmailPrevNextBtn from "./EmailPrevNextBtn";
import EmailStatusChip from "./EmailStatusChip";

interface EmailToolbarProps {
  emailId: number;
  email?: IEmailRecord;
}

export default function EmailToolbar({ emailId, email }: EmailToolbarProps) {
  const navigate = useNavigate();

  function handleDeleteSuccess() {
    if (email?.workflow?.id) {
      navigate(`/workflow/${email.workflow.id}`);
    } else {
      navigate(`/`);
    }
  }

  return (
    <Stack direction="row" gap={1} alignItems="center">
      {email && (
        <>
          <EmailStatusChip level={email.status} variant="outlined" />
          <EmailPrevNextBtn action="next" email={email} />
          <EmailPrevNextBtn action="prev" email={email} />
        </>
      )}
      <DownloadCsvBtn variant="icon" ids={[emailId]} disabled={email?.status !== "PROCESSED"} />
      <DeleteEmailBtn ids={[emailId]} onDeleteSuccess={handleDeleteSuccess} />
    </Stack>
  );
}
