import { SearchUISchemaField } from "@features/email/emailDetailPage/tabs/fields/dynamicForm/dynamicFormTypes";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { debounce } from "@mui/material/utils";
import React, { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";

export interface IAutocompleteOption {
  id: string;
  option_text: string;
  option_details?: string[];
}

export interface IAutocompleteRequest {
  id?: string;
  search?: string;
}

export interface IAutocompleteResponse {
  results: IAutocompleteOption[];
}

interface Props {
  advancedSearchParameters?: SearchUISchemaField["advancedSearchParameters"];
  disabled?: boolean;
  inputProps?: React.ComponentProps<typeof TextField>;
  label: string;
  lazyQuery: any;
  name: string;
  onBlur: () => void;
  onChange: (value: string) => void;
  searchModel: string;
  value: string;
}

const ServerSideSearchSelect2 = forwardRef<HTMLDivElement, Props>(
  (
    {
      advancedSearchParameters,
      disabled,
      inputProps,
      label,
      lazyQuery,
      name,
      onBlur,
      onChange: onChangeProps,
      searchModel,
      value,
    },
    ref,
  ) => {
    const form = useFormContext();
    const extraParameterValues = form.watch(advancedSearchParameters?.map((x) => `${x.valueFromField}.value`) ?? []);

    const [extraParameters, setExtraParameters] = useState<Record<string, any>>(
      extraParameterValues.reduce(
        (acc, curr, index) => {
          if (curr) {
            acc[advancedSearchParameters![index].key] = curr;
          }

          return acc;
        },
        {} as Record<string, any>,
      ),
    );
    const [selectedOption, setSelectedOption] = useState<IAutocompleteOption | null>(null);
    const [inputValue, setInputValue] = useState("");

    const [trigger, { data, isFetching, isUninitialized }] = lazyQuery();

    const debouncedTrigger = debounce(trigger, 300);

    const options = useMemo(() => {
      const typedResults = (data?.results ?? []) as IAutocompleteOption[];
      const uniqueResults = typedResults.filter(
        (option, index, arr) => index === arr.findIndex((t) => t.id === option.id),
      );

      return uniqueResults;
    }, [data?.results]);

    // Update extraParameters only when extraParametersValues values change
    useEffect(() => {
      const newExtraParameters = extraParameterValues.reduce(
        (acc, curr, index) => {
          if (curr) {
            acc[advancedSearchParameters![index].key] = curr;
          }

          return acc;
        },
        {} as Record<string, any>,
      );

      if (JSON.stringify(extraParameters) !== JSON.stringify(newExtraParameters)) {
        setExtraParameters(newExtraParameters);
        trigger({ searchModel, params: { ...newExtraParameters, search: inputValue } });
      }
    }, [advancedSearchParameters, extraParameterValues, extraParameters, inputValue, searchModel, trigger]);

    // When component mounts, if value is not empty, fetch the selected option
    useEffect(() => {
      if (value && value !== selectedOption?.id) {
        trigger({ searchModel, params: { id: value, ...extraParameters, search: "" } });
      } else {
        trigger({ searchModel, params: { ...extraParameters, search: "" } });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (value === undefined || value.trim().length === 0) {
        setSelectedOption(null);
        return;
      }

      const foundSelectedOption = options.find((x) => x.id.toString() === value.toString());

      if (foundSelectedOption) {
        if (foundSelectedOption.id !== selectedOption?.id) {
          setSelectedOption(foundSelectedOption);
        }

        if (foundSelectedOption.id !== value) {
          onChangeProps(foundSelectedOption.id);
        }
      } else if (!selectedOption && !isUninitialized && !isFetching) {
        // This is the case where the pre-selected option is not found (e.g. deleted from the database)
        setSelectedOption(null);
        onChangeProps("");
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, options]);

    const handleInputChange = useCallback(
      (_: any, value: string) => {
        setInputValue(value);
        debouncedTrigger({ searchModel, params: { ...extraParameters, search: value } });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [searchModel, extraParameters],
    );

    // Handle user selecting an option
    const handleChange = useCallback(
      (_: any, selectedOption: IAutocompleteOption | null) => {
        if (selectedOption) {
          onChangeProps(selectedOption.id);
        } else {
          onChangeProps("");
        }
      },
      [onChangeProps],
    );

    const renderOption = (props: any, option: IAutocompleteOption) => (
      <li {...props} key={`listItem-${option.id}`}>
        <Box>
          <Typography variant="body1">{option.option_text}</Typography>
          {option.option_details &&
            option.option_details.map((val) => (
              <Typography variant="caption" key={val} display="block">
                {val}
              </Typography>
            ))}
        </Box>
      </li>
    );

    return (
      <Autocomplete
        ref={ref}
        disabled={disabled}
        filterOptions={(x) => x}
        getOptionLabel={(option) => option.option_text?.toString()}
        inputValue={inputValue}
        isOptionEqualToValue={(option, value) => option.id.toString() === value.id.toString()}
        loading={isFetching}
        noOptionsText="Nessun risultato"
        onBlur={onBlur}
        onChange={handleChange}
        onInputChange={handleInputChange}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            {...inputProps}
            disabled={disabled || inputProps?.disabled}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            label={label}
            name={name}
          />
        )}
        renderOption={renderOption}
        value={selectedOption}
      />
    );
  },
);

export default ServerSideSearchSelect2;
