import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

export default function LoadingPlaceholder() {
  const { t } = useTranslation();

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      gap={2}
      sx={{
        width: "100%",
        height: "75%",
      }}
    >
      <CircularProgress />
      <Typography fontWeight={"bold"} variant="h6" component="h1" noWrap sx={{ ml: 2 }}>
        {t("common.loading")}
      </Typography>
    </Stack>
  );
}
