import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import JsonView from "react18-json-view";
import "react18-json-view/src/style.css";
import { HighlightAreaWithLabelAndId } from "../EmailDetailPage";
import EmailArtifactsTab from "./artifacts/EmailArtifactsTab";
import EmailFieldsTab from "./fields/EmailFieldsTab";
import { getTabPlugins } from "./plugins/TabPluginRegistry";

interface EmailTabsColumnProps {
  email: any;
  isPatching: boolean;
  isFetching: boolean;
  onSubmit: (data: any) => void;
  handleTarget: (target: HighlightAreaWithLabelAndId) => void;
  page_idx: number;
  tabID: string;
  src_ref: { type: string; id: string };
}

interface TabDefinition {
  id: string;
  label: string;
  priority: number;
  content: React.ReactNode;
}

function EmailTabsColumn({
  email,
  isPatching,
  isFetching,
  onSubmit,
  handleTarget,
  page_idx,
  tabID,
  src_ref,
}: EmailTabsColumnProps) {
  const { t } = useTranslation();

  // Hold a reference to the (child) email form so that we may inspect its state.
  const [emailForm, setEmailForm] = useState<UseFormReturn<any> | null>(null);
  const [isEmailFormDirty, setEmailFormDirty] = useState(false);
  const [activeTabId, setActiveTabId] = useState("fields");

  // Define your built-in tabs. Note here that we use custom string IDs.
  const builtInTabs: TabDefinition[] = useMemo(
    () => [
      {
        id: "fields",
        label: t("email.extractedData"),
        priority: 0,
        content: (
          <TabPanel value="fields" sx={{ height: "100%", p: 0 }}>
            <EmailFieldsTab
              key={email.id}
              email={email}
              isLoading={isPatching || isFetching}
              onSubmit={onSubmit}
              onTarget={handleTarget}
              page_idx={page_idx}
              tabID={tabID}
              // These two callbacks let EmailFieldsTab report its form instance and dirty state upward:
              onFormInit={(form) => setEmailForm(form)}
              onDirtyChange={(dirty) => setEmailFormDirty(dirty)}
            />
          </TabPanel>
        ),
      },
      {
        id: "artifacts",
        label: t("email.artifacts"),
        priority: -20,
        content: (
          <TabPanel value="artifacts" sx={{ height: "100%", p: 0 }}>
            <EmailArtifactsTab artifacts={email.artifacts} />
          </TabPanel>
        ),
      },
      // {
      //   id: "events",
      //   label: t("email.alerts"),
      //   content: (
      //     <TabPanel value="events" sx={{ height: "100%", p: 0 }}>
      //       <EmailEventsTab events={email.events} />
      //     </TabPanel>
      //   ),
      // },
      {
        id: "code",
        label: t("email.codeExport"),
        priority: -40,
        content: (
          <TabPanel value="code" sx={{ height: "100%", p: 0 }}>
            <Box sx={{ p: 3 }}>
              <Typography variant="body1" component="p" sx={{ mb: 2 }}>
                <JsonView
                  src={email.parsed_info}
                  collapsed={2}
                  collapseStringMode="directly"
                  collapseStringsAfterLength={30}
                />
              </Typography>
            </Box>
          </TabPanel>
        ),
      },
    ],
    [email, handleTarget, isFetching, isPatching, onSubmit, page_idx, t, tabID],
  );

  const pluginTabs = useMemo(
    () =>
      getTabPlugins(email.workflow.name).map((plugin) => ({
        id: `plugin-${plugin.id}`,
        label: plugin.label,
        priority: plugin.priority,
        content: (
          <TabPanel value={`plugin-${plugin.id}`} sx={{ height: "100%", p: 0 }}>
            {emailForm ? plugin.render(emailForm, email, t) : <Typography>{t("email.loadingPlugin")}</Typography>}
          </TabPanel>
        ),
      })),
    [email, emailForm, t],
  );

  // Combine all tabs together.
  const allTabs = useMemo(
    () => [...builtInTabs, ...pluginTabs].sort((a, b) => b.priority - a.priority),
    [builtInTabs, pluginTabs],
  );

  // When switching tabs, if we’re leaving the “fields” tab and the email form is dirty, warn the user.
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    if (activeTabId === "fields" && isEmailFormDirty && newValue !== "fields") {
      const confirmLeave = window.confirm(t("email.unsavedChangesTabChangeAlert"));
      if (!confirmLeave) {
        return;
      }
    }
    setActiveTabId(newValue);
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <TabContext value={activeTabId}>
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%", px: 1 }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={activeTabId}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable tabs"
            >
              {allTabs.map((tab) => (
                <Tab key={tab.id} label={tab.label} value={tab.id} />
              ))}
            </Tabs>
          </Box>
          <Box sx={{ flexGrow: 1, overflowY: "auto" }}>
            {allTabs.map((tab) => (
              <React.Fragment key={tab.id}>{tab.content}</React.Fragment>
            ))}
          </Box>
        </Box>
      </TabContext>
    </div>
  );
}

export default EmailTabsColumn;
