import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDeleteEmailMutation } from "../../../app/services/appApi";
import { IconBtn } from "../../input/Btn";

type ButtonVariant = "text" | "outlined" | "contained" | "icon";

interface IDeleteEmailBtnProps extends Omit<React.ComponentProps<typeof Button>, "variant"> {
  ids: number[];
  variant?: ButtonVariant;
  onDeleteSuccess?: () => void;
}

export default function DeleteEmailBtn({ ids, variant = "icon", onDeleteSuccess, ...props }: IDeleteEmailBtnProps) {
  const { t } = useTranslation();

  const [deleteEmail, { isLoading, isError, isSuccess }] = useDeleteEmailMutation();

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(t("email.deleteEmailFailure"), { variant: "error" });
    } else if (isSuccess) {
      enqueueSnackbar(t("email.deleteEmailSuccess"), { variant: "success" });
      onDeleteSuccess?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, isSuccess, t]);

  if (ids.length >= 1) {
    return (
      <div>
        <IconBtn
          disabled={isLoading || ids.length === 0}
          onClick={() => ids.forEach((id) => deleteEmail(id))}
          color="error"
          askConfirm
          confirmMsg={ids.length > 1 ? t("email.deleteEmailsConfirmMessage") : t("email.deleteEmailConfirmMessage")}
        >
          <DeleteIcon />
        </IconBtn>
      </div>
    );
  }
}
