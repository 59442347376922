import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import React from "react";

const Section = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  "& > h2": {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
}));

const SubSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  "& > h3": {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1),
  },
}));

const ExampleBox = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.grey[50],
  "& .json-view": {
    backgroundColor: "transparent !important",
  },
}));

const FieldType = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontFamily: "monospace",
  backgroundColor: theme.palette.grey[100],
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.spacing(0.5),
  display: "inline-block",
}));

const SchemaDocsDialog = () => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <InputAdornment position="end">
        <IconButton onClick={handleOpen} size="small" edge="end" aria-label="schema documentation">
          <HelpOutlineIcon />
        </IconButton>
      </InputAdornment>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle>
          <Typography variant="h4" component="h1">
            Tool Config Documentation
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Section>
            <Typography variant="h5" component="h2">
              Sections
            </Typography>
            <Typography variant="body1" gutterBottom>
              The Tool config let you create a new custom workflow thanks to agent workflow.
            </Typography>
          </Section>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SchemaDocsDialog;
