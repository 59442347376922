import { IAttachmentRecord } from "@features/attachment/attachmentTypes";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Paper, Tab, Tabs } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import "react18-json-view/src/style.css";
import { HighlightAreaWithLabelAndId } from "../EmailDetailPage";
import EmailBody from "./EmailBody";
import PDFViewer from "./PDFViewer";

interface EmailContentProps {
  email: any;
  tabID: string;
  handleAttachmentChange: (event: React.SyntheticEvent, newValue: string) => void;
  activeArea: HighlightAreaWithLabelAndId | null;
}

function EmailContent({ email, tabID, handleAttachmentChange, activeArea }: EmailContentProps) {
  const { t } = useTranslation();

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Paper sx={{ height: "100%" }}>
        <TabContext value={tabID}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabID}
              onChange={handleAttachmentChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="attachment tabs"
            >
              <Tab label={t("email.email")} value="email" />
              {email.attachments.map((attachment: IAttachmentRecord, index: number) => (
                <Tab key={index} label={attachment.filename} value={`attachment::${attachment.id}`} />
              ))}
            </Tabs>
          </Box>
          <TabPanel value="email" sx={{ height: "calc(100% - 48px)", p: 2, overflowY: "auto" }}>
            <EmailBody email={email} />
          </TabPanel>
          {email.attachments.map((attachment: any, index: number) => (
            <TabPanel key={index} value={`attachment::${attachment.id}`} sx={{ height: "calc(100% - 48px)", p: 0 }}>
              <PDFViewer fileUrl={attachment.presigned_url} activeArea={activeArea} />
            </TabPanel>
          ))}
        </TabContext>
      </Paper>
    </div>
  );
}

export default EmailContent;
