import belliniExternalValidationPlugin from "./bellini/oda/belliniValidationPlugin";
import { ExternalValidationPlugin } from "./externalValidationTypes";

/**
 * Get the validation plugin by name
 * @param workflowName The name of the workflow
 * @returns The plugin
 */
export function getFormValidationPlugins(workflowName: string): ExternalValidationPlugin[] {
  return [
    // vvvvvvv Import the plugins here vvvvvvv
    belliniExternalValidationPlugin,
  ].filter((plugin) => plugin.shouldLoad(workflowName));
}
