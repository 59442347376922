import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import React, { ReactNode, forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";

interface ILoadingWrapperProps {
  fakeDelayMs?: number;
  loading?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  askConfirm?: boolean;
  confirmTitle?: string;
  confirmMsg?: string;
  render: (props: {
    loading: boolean;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    ref: React.Ref<any>;
  }) => ReactNode;
}

const LoadingWrapper = forwardRef<HTMLButtonElement, ILoadingWrapperProps>(
  (
    {
      fakeDelayMs = 600,
      loading: isRealLoading = false,
      onClick = () => {},
      askConfirm = false,
      confirmTitle,
      confirmMsg,
      render,
      ...other
    },
    ref,
  ) => {
    const { t } = useTranslation();

    const [isFakeLoading, setIsFakeLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const handleBtnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      if (askConfirm) {
        setOpen(true);
      } else {
        handleConfirm(e);
      }
    };

    const handleConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
      setOpen(false);
      setIsFakeLoading(true);
      setTimeout(() => {
        onClick(e);
        setIsFakeLoading(false);
      }, fakeDelayMs);
    };

    const handleAbort = () => {
      setOpen(false);
    };

    return (
      <>
        {render({
          loading: isRealLoading || isFakeLoading,
          onClick: handleBtnClick,
          ref,
          ...other,
        })}
        <Dialog
          open={open}
          onClose={handleAbort}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{confirmTitle ?? t("common.confirmAction")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {confirmMsg ?? t("common.areYouSureDeleteElement")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAbort}>{t("common.cancel")}</Button>
            <Button onClick={handleConfirm} autoFocus>
              {t("common.confirm")}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  },
);

interface BtnProps extends IconButtonProps, Omit<ILoadingWrapperProps, "render"> {
  children: ReactNode;
}

const IconBtn = forwardRef<HTMLButtonElement, BtnProps>(({ children, ...props }, ref) => (
  <LoadingWrapper
    {...props}
    ref={ref}
    render={({ loading, onClick, ...other }) => (
      <IconButton disabled={loading} onClick={onClick} {...other}>
        {children}
      </IconButton>
    )}
  />
));

const Btn = forwardRef<HTMLButtonElement, BtnProps>(({ children, ...props }, ref) => (
  <LoadingWrapper
    {...props}
    ref={ref}
    render={({ loading, onClick, ...other }) => (
      <LoadingButton loading={loading} onClick={onClick} {...other}>
        {children}
      </LoadingButton>
    )}
  />
));

export { Btn, IconBtn };
