import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

export default function ErrorPlaceholder() {
  const { t } = useTranslation();

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      gap={2}
      sx={{
        width: "100%",
        height: "75%",
      }}
    >
      <ErrorOutlineIcon color="error" sx={{ fontSize: 60 }} />
      <Typography fontWeight={"bold"} variant="h6" component="h1">
        {t("common.genericError")}
      </Typography>
      <Typography variant="body1" component="p">
        {t("email.emailNotFound")}
      </Typography>
    </Stack>
  );
}
