import AddIcon from "@mui/icons-material/Add";
import RouteIcon from '@mui/icons-material/Route';
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
  Avatar,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useGetWorkflowsQuery } from "../../app/services/appApi";
import { withAuth } from "../auth/authWrappers";
import { IWorkflowRead } from "../auth/types";

const WorkflowCard = ({ workflow }: { workflow: IWorkflowRead }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleEditClick = (id: number) => {
    navigate(`/workflow/${id}`);
  };

  const { id, email_addr, count_emails, count_emails_current_month, count_emails_last_24h, title, subtitle, ratelimit } = workflow;

  const progressPercentage = Math.min((count_emails_current_month / ratelimit) * 100, 100);

  return (
    <Grid item xs={12} md={6} lg={4}>
      <Card elevation={3} sx={{ height: '100%', display: 'flex', flexDirection: 'column', transition: 'transform 0.2s', '&:hover': { transform: 'scale(1.02)' } }}>
        <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <Stack direction="row" alignItems="center" spacing={2} mb={2}>
            <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
              <RouteIcon />
            </Avatar>
            <Box>
              <Typography variant="h6" component="div" noWrap>
                <b>{title}</b>
              </Typography>
              <Typography variant="body2" color="text.secondary" noWrap>
                {email_addr}
              </Typography>
            </Box>
          </Stack>

          <Box mb={3} display="flex" justifyContent="space-between">
            <StatBox label="Totale" value={count_emails} />
            <StatBox label="Mese corrente" value={`${count_emails_current_month}/${ratelimit}`} />
            <StatBox label="Ultime 24 ore" value={count_emails_last_24h} />
          </Box>
          <LinearProgress
            variant="determinate"
            value={progressPercentage}
            sx={{
              height: 8,
              borderRadius: 4,
              mb: 2,
              backgroundColor: theme.palette.grey[200],
              '& .MuiLinearProgress-bar': {
                borderRadius: 4,
                backgroundColor: progressPercentage > 90 ? theme.palette.error.main : theme.palette.primary.main,
              },
            }}
          />
          <Button
            variant="outlined"
            onClick={() => handleEditClick(id)}
            endIcon={<ArrowForwardIcon />}
            sx={{ mt: 'auto', alignSelf: 'flex-end' }}
          >
            Visualizza
          </Button>
        </CardContent>
      </Card>
    </Grid >
  );
};
const StatBox = ({ label, value }: { label: string; value: string | number }) => (
  <Box textAlign="center" sx={{ bgcolor: (theme) => theme.palette.background.default, p: 1, borderRadius: 1 }}>
    <Typography variant="h6" fontWeight="bold">{value}</Typography>
    <Typography variant="caption" color="text.secondary">{label}</Typography>
  </Box>
);

const DisabledWorkflowCard = () => {
  const theme = useTheme();

  return (
    <Grid item xs={12} md={6} lg={4}>
      <Card
        elevation={0}
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          opacity: 0.6,
          border: `2px dashed ${theme.palette.grey[400]}`,
          backgroundColor: theme.palette.background.default,
        }}
      >
        <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <Tooltip title="Non hai i permessi per aggiungere nuovi flussi di lavoro" arrow>
            <Box textAlign="center">
              <AddIcon sx={{ fontSize: 60, color: theme.palette.grey[500], mb: 2 }} />
              <Typography variant="h6" color="text.secondary">
                Nuovo flusso di lavoro
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Crea una nuova automazione
              </Typography>
            </Box>
          </Tooltip>
        </CardContent>
      </Card>
    </Grid>
  );
};


const WorkflowListPage = () => {
  const { data, isLoading } = useGetWorkflowsQuery({});
  const rows = data?.results || [];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography variant="h5" component="h1" noWrap sx={{ mb: 3, display: "flex", alignItems: "center", gap: 1 }}>
        <RouteIcon />
        <b>Flussi di lavoro</b>
      </Typography>
      <Grid container spacing={3}>
        {isLoading ? (
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        ) : (
          <>
            {rows.map((workflow) => (
              <WorkflowCard key={workflow.id} workflow={workflow} />
            ))}
            <DisabledWorkflowCard />
          </>
        )}
      </Grid>
    </Box>
  );
};

export default withAuth(WorkflowListPage);