import { Box, Paper, Typography, Container, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { withAuth } from "../auth/authWrappers";
import SyncIcon from "@mui/icons-material/Sync";
import SyncAllComponent from "./SyncAllComponent";

const SyncPage: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Box display="flex" alignItems="center" mb={4}>
          <SyncIcon sx={{ fontSize: 40, color: theme.palette.primary.main, mr: 2 }} />
          <Typography variant="h4" component="h1" fontWeight="bold">
            {t("sync.title")}
          </Typography>
        </Box>

        <Typography variant="h6" gutterBottom>
          {t("sync.subtitle")}
        </Typography>

        <Typography variant="body1" color="text.secondary" paragraph>
          {t("sync.description")}
        </Typography>

        <Box mt={4}>
          <SyncAllComponent />
        </Box>
      </Paper>
    </Container>
  );
};

export default withAuth(SyncPage);