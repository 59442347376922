import { appApi } from "@app/services/appApi";
import { store } from "@app/store";
import { ExternalValidationPlugin } from "../../externalValidationTypes";
import { validateBelliniData } from "./belliniValidationLogic";
import BelliniValidationSchema from "./belliniValidationSchema";
import { BelliniFormData } from "./belliniValidationTypes";

const belliniExternalValidationPlugin: ExternalValidationPlugin<BelliniFormData> = {
  shouldLoad: (workflowName: string) => workflowName === "bellini_oda",

  getResolver: () => BelliniValidationSchema,

  validate: async (formData: BelliniFormData) => {
    const productIds =
      formData.items?.filter((item) => item.product_id?.value).map((item) => item.product_id!.value) || [];

    if (productIds.length === 0) {
      return { isValid: true };
    }

    try {
      const results = await Promise.all(
        productIds.map((id) => store.dispatch(appApi.endpoints.getProduct.initiate(id as number))),
      );

      const errors = results.filter((result) => result.error);
      if (errors.length > 0) {
        throw errors[0].error;
      }

      const productCatalog = results
        .map((result) => result.data)
        .filter((product): product is NonNullable<typeof product> => product !== undefined);

      return validateBelliniData(formData, { productCatalog });
    } catch (error) {
      alert("Internal error: Failed to validate Bellini data. Validation will be skipped.");
      console.error("Failed to validate Bellini data:", error);
      return { isValid: true }; // Skip validation on error
    }
  },
};

export default belliniExternalValidationPlugin;
