import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import { FormControlLabel, Switch } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { skipToken } from "@reduxjs/toolkit/query";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useCreateWorkflowMutation, useGetWorkflowQuery, useUpdateWorkflowMutation } from "../../app/services/appApi";
import { IWorkflowCreate, IWorkflowRead, IWorkflowUpdate } from "../auth/types";
import SchemaDocsDialog from "./SchemaDocsDialog";
import ToolDocsDialog from "./ToolDocsDialog";
const DEFAULT_CREATE_VALUES: IWorkflowCreate = {
  code: "",
  title: "",
  subtitle: "",
  ratelimit: 1000,
  ui_schema: "",
  extraction_schema: "",
  extraction_extra_context: "",
  name: "",
  organization_id: "",
  enable_register: false,
  memories: "",
  tool_config: "",
};

const cleanReadWorkflowDataForUpdate = (workflow: IWorkflowRead): IWorkflowUpdate => {
  const {
    id,
    code,
    title,
    subtitle,
    ratelimit,
    name,
    created_at,
    updated_at,
    ui_schema,
    organization,
    extraction_extra_context,
    extraction_schema,
    enable_register,
    memories,
    tool_config,
  } = workflow;
  return {
    code,
    title,
    subtitle,
    ratelimit,
    name,
    ui_schema: JSON.stringify(ui_schema),
    extraction_schema: JSON.stringify(extraction_schema),
    extraction_extra_context,
    organization_id: organization.id,
    enable_register,
    memories: workflow.memories,
    tool_config: workflow.tool_config,
  };
};

interface IWorkflowFormDialogProps {
  workflowId?: IWorkflowRead["id"];
  render?: (props: { onClick: () => void }) => React.ReactNode;
}

/**
 * Dialog component for creating and editing workflows.
 * It can be opened in two modes:
 * 1. Create mode: workflowId is not given
 * 2. Edit mode: workflowId is given
 *
 * In edit mode, the user data is fetched from the API so
 * that the form can be pre-filled with the existing user data.
 */
const WorkflowFormDialog: React.FC<IWorkflowFormDialogProps> = ({ workflowId, render }) => {
  const [open, setOpen] = useState(false);

  const createMode: boolean = !workflowId;

  const form = useForm<IWorkflowCreate | IWorkflowUpdate>();

  // Delay data fetching until userId is defined and dialog is opened
  const { data: workflow, isLoading: isDataLoading } = useGetWorkflowQuery(workflowId ?? skipToken, { skip: !open });

  const [triggerCreate, { isLoading: isCreateLoading, isSuccess: isCreateSuccess, isError: isCreateError }] =
    useCreateWorkflowMutation();
  const [triggerUpdate, { isLoading: isUpdateLoading, isSuccess: isUpdateSuccess, isError: isUpdateError }] =
    useUpdateWorkflowMutation();

  const isLoading = isCreateLoading || isUpdateLoading || isDataLoading;

  React.useEffect(() => {
    if (workflow) {
      form.reset(cleanReadWorkflowDataForUpdate(workflow));
    } else {
      form.reset(DEFAULT_CREATE_VALUES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflow]);

  const handleClose = () => {
    setOpen(false);
  };

  const sendData = (data: IWorkflowCreate | IWorkflowUpdate) => {
    // Convert ui_schema to JSON
    data.ui_schema = JSON.parse(data.ui_schema);
    data.extraction_schema = data.extraction_schema ? JSON.parse(data.extraction_schema) : null;
    if (createMode) {
      triggerCreate(data as IWorkflowCreate);
    } else {
      triggerUpdate({ id: workflowId || -1, ...data });
    }
  };

  // Handle error and success notifications
  useEffect(() => {
    if (isCreateError) {
      enqueueSnackbar("Errore creazione", { variant: "error" });
    }
    if (isCreateSuccess) {
      enqueueSnackbar("Creata con successo", { variant: "success" });
      handleClose();
    }
    if (isUpdateError) {
      enqueueSnackbar("Impossibile aggiornare", { variant: "error" });
    }
    if (isUpdateSuccess) {
      enqueueSnackbar("Aggiornata con successo", { variant: "success" });
      handleClose();
    }
  }, [isCreateError, isCreateSuccess, isUpdateError, isUpdateSuccess]);

  const openButton = render ? (
    render({ onClick: () => setOpen(true) })
  ) : (
    <IconButton onClick={() => setOpen(true)}>
      <EditOutlinedIcon />
    </IconButton>
  );
  return (
    <>
      {openButton}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{createMode ? "Crea nuova workflow" : "Modifica workflow"}</DialogTitle>
        {!isDataLoading ? (
          <form onSubmit={form.handleSubmit(sendData)}>
            <DialogContent>
              <Stack gap={2}>
                <Controller
                  name="code"
                  rules={{ required: true, maxLength: 6 }}
                  control={form.control}
                  defaultValue=""
                  render={({ field, fieldState }) => (
                    <TextField
                      autoFocus
                      label={"Code"}
                      type="text"
                      placeholder="123456"
                      fullWidth
                      required
                      error={!!fieldState.error}
                      helperText={
                        !!fieldState.error
                          ? "Il campo deve contenere massimo 6 caratteri"
                          : "Codice a 6 cifre es. 123456"
                      }
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="title"
                  rules={{ required: true }}
                  control={form.control}
                  defaultValue=""
                  render={({ field, fieldState }) => (
                    <TextField
                      label={"Title"}
                      type="text"
                      placeholder="La mia workflow"
                      fullWidth
                      required
                      error={!!fieldState.error}
                      helperText={!!fieldState.error ? "Il campo è obbligatorio" : "Nome workflow da mostrare nella UI"}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="subtitle"
                  rules={{ required: true }}
                  control={form.control}
                  defaultValue=""
                  render={({ field, fieldState }) => (
                    <TextField
                      label={"Subtitle"}
                      type="text"
                      placeholder="Workflow che fa cose"
                      fullWidth
                      required
                      error={!!fieldState.error}
                      helperText={
                        !!fieldState.error ? "Il campo è obbligatorio" : "Sottotitolo workflow da mostrare nella UI"
                      }
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="organization_id"
                  rules={{ required: true }}
                  control={form.control}
                  defaultValue=""
                  render={({ field, fieldState }) => (
                    <TextField
                      label={"ID Organizzazione"}
                      type="text"
                      placeholder="42"
                      fullWidth
                      required
                      error={!!fieldState.error}
                      helperText={
                        !!fieldState.error
                          ? "Il campo è obbligatorio"
                          : "ID dell'organizzazione a cui associare la workflow"
                      }
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="ratelimit"
                  rules={{ required: true }}
                  control={form.control}
                  defaultValue={1000}
                  render={({ field, fieldState }) => (
                    <TextField
                      label={"Ratelimit mensile"}
                      type="text"
                      placeholder="1000"
                      fullWidth
                      required
                      error={!!fieldState.error}
                      helperText={!!fieldState.error && "Il campo è obbligatorio"}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="extraction_extra_context"
                  control={form.control}
                  defaultValue=""
                  rules={{ maxLength: 4096 }}
                  render={({ field, fieldState }) => (
                    <TextField
                      label={"Extraction extra context"}
                      type="text"
                      fullWidth
                      multiline
                      placeholder="# Additional context\n- The document is a purchase order"
                      minRows={4}
                      maxRows={20}
                      error={!!fieldState.error}
                      helperText={
                        !!fieldState.error
                          ? "Il campo deve contenere massimo 4096 caratteri"
                          : "Opzionale. Per workflow generiche. Specifica il contesto dell'estrazione in markdown. max 4096 caratteri"
                      }
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="extraction_schema"
                  control={form.control}
                  defaultValue=""
                  render={({ field, fieldState }) => (
                    <TextField
                      label={"Extraction JsonSchema"}
                      type="text"
                      fullWidth
                      multiline
                      placeholder="{}"
                      minRows={4}
                      maxRows={20}
                      error={!!fieldState.error}
                      helperText={"Opzionale. Per workflow generiche"}
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="ui_schema"
                  rules={{ required: true }}
                  control={form.control}
                  defaultValue=""
                  render={({ field, fieldState }) => (
                    <TextField
                      label={"Ui Schema"}
                      type="text"
                      fullWidth
                      placeholder="{}"
                      required
                      multiline
                      minRows={4}
                      maxRows={20}
                      error={!!fieldState.error}
                      helperText={
                        fieldState.error ? "Il campo è obbligatorio" : "Schema per determinare quali campi UI mostrare"
                      }
                      InputProps={{
                        endAdornment: <SchemaDocsDialog />,
                      }}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="memories"
                  control={form.control}
                  defaultValue=""
                  rules={{ maxLength: 1024 }}
                  render={({ field, fieldState }) => (
                    <TextField
                      label={"Memories"}
                      type="text"
                      fullWidth
                      multiline
                      placeholder="# Additional context"
                      minRows={4}
                      maxRows={20}
                      error={!!fieldState.error}
                      helperText={
                        !!fieldState.error
                          ? "Il campo deve contenere massimo 1024 caratteri"
                          : "Opzionale. Possibilità di aggiungere delle note e memorie per la workflow. Il numero massimo di caratteri è 1024"
                      }
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="enable_register"
                  control={form.control}
                  defaultValue={false}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      control={<Switch checked={value} onChange={onChange} />}
                      label="Abilita connessione gestionale"
                    />
                  )}
                />

                <Controller
                  name="name"
                  rules={{ required: true }}
                  control={form.control}
                  defaultValue=""
                  render={({ field, fieldState }) => (
                    <TextField
                      label={"Workflow Name"}
                      type="text"
                      placeholder="base_ddt"
                      fullWidth
                      required
                      error={!!fieldState.error}
                      helperText={
                        !!fieldState.error
                          ? "Il campo è obbligatorio"
                          : "Nome da passare nel create_workflow_by_name(...)"
                      }
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="tool_config"
                  rules={{ required: true }}
                  control={form.control}
                  defaultValue=""
                  render={({ field, fieldState }) => (
                    <TextField
                      label={"Tool Config"}
                      type="text"
                      fullWidth
                      placeholder="{}"
                      required
                      multiline
                      minRows={4}
                      maxRows={20}
                      error={!!fieldState.error}
                      helperText={
                        fieldState.error
                          ? "Il campo è obbligatorio"
                          : "Schema per determinare l'agent workflow da utilizzare"
                      }
                      InputProps={{
                        endAdornment: <ToolDocsDialog />, //#TODO need to change documentation
                      }}
                      {...field}
                    />
                  )}
                />
              </Stack>
            </DialogContent>
            <DialogActions sx={{ m: 2 }}>
              <Button startIcon={<CloseOutlinedIcon />} onClick={handleClose}>
                Annulla
              </Button>
              <LoadingButton
                startIcon={<CheckOutlinedIcon />}
                loading={isLoading}
                disabled={isLoading}
                variant="contained"
                type="submit"
              >
                {createMode ? "Crea" : "Aggiorna"}
              </LoadingButton>
            </DialogActions>
          </form>
        ) : (
          <DialogContent>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
};
export default WorkflowFormDialog;
