import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { Button, IconButton, Tooltip } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useGenerateCsvMutation } from "../../../app/services/appApi";

// Common types and utilities
type ButtonVariant = "text" | "outlined" | "contained" | "icon";

interface DownloadCsvBtnProps extends Omit<React.ComponentProps<typeof Button>, "variant"> {
  ids: number[];
  variant?: ButtonVariant;
}

const downloadCsv = (content: string) => {
  const blob = new Blob([content], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  const date = new Date();
  const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}-${date.getHours()}${date.getMinutes()}${date.getSeconds()}-export.csv`;
  a.download = formattedDate;
  a.click();
};

const DownloadCsvBtn: React.FC<DownloadCsvBtnProps> = ({ ids, variant = "contained", ...props }) => {
  const { t } = useTranslation();

  const [generateCsv, { data, isError, isSuccess }] = useGenerateCsvMutation();

  useEffect(() => {
    if (isSuccess && data) {
      downloadCsv(data.content);
      enqueueSnackbar(t("email.csvGenerationSuccess"), { variant: "success" });
    } else if (isError) {
      enqueueSnackbar(t("email.csvGenerationFailure"), { variant: "error" });
    }
  }, [isSuccess, isError, data, t]);

  const handleClick = () => generateCsv(ids);

  if (variant === "icon") {
    return (
      <Tooltip title={t("email.downloadCsv")}>
        <div>
          <IconButton color="primary" disabled={ids.length === 0} onClick={handleClick} {...props}>
            <SaveAltIcon />
          </IconButton>
        </div>
      </Tooltip>
    );
  }

  return (
    <Button
      size="small"
      variant={variant}
      color="primary"
      startIcon={<SaveAltIcon />}
      disabled={ids.length === 0}
      onClick={handleClick}
      sx={{ mt: 2 }}
      {...props}
    >
      {t("email.downloadCsv")}
    </Button>
  );
};

export default DownloadCsvBtn;
